import './App.css';
import React, { useState,useEffect } from "react";
import { MainLayout } from './components/MainLayout';
import { Amplify} from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import { withAuthenticator } from "@aws-amplify/ui-react";
import awsmobile from "./aws-exports";
import Login from './components/Login/Login';
import { Auth, Analytics } from "aws-amplify";
import { Route, Routes } from "react-router-dom";
import { Navigate } from "react-router-dom";


Amplify.configure(awsmobile);





function App() {
const [mainscreen, setMainscreen] = useState(false);
const [email, setEmail] = useState('');
const [userRole, setUserRole] = useState('');
const [userEmail, setUserEmail] = useState('');


{/*useEffect(() => {
  async function fetchData() {
    const { accessToken: { jwtToken } } = await Auth.currentSession();
    const user = await Auth.currentAuthenticatedUser();
    const email = user.attributes.email;
    setUserEmail(email)
    try {
      const response = await fetch(
        `/api/admin/getUserRole?user_email=${email}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": jwtToken,
          },
        }
      );
      const data = await response.json();
      setUserRole(data[0].user_role)

    } catch (error) {
      console.error("Error :", error);
    }
  }
  fetchData()
}, [])

const ProtectedRoute = ({ userRole, allowedRoles, children }) => {
  if (!userRole) {
    // Wait for userRole to load
    return null;
  }
  return allowedRoles.includes(userRole) ? children : <Navigate to="/" replace />;
};


  return (
    <Routes>
       <Route path="/explore" element={<ProtectedRoute path="/explore" userRole={userRole} allowedRoles={["admin"]} ><MainLayout email={userEmail} setEmail={setEmail}/></ProtectedRoute>} />
       <Route path="/" element={<ProtectedRoute path="/" userRole={userRole} allowedRoles={["admin"]} > <Login email={userEmail} setEmail={setEmail} mainscreen={mainscreen} setMainscreen={setMainscreen}/></ProtectedRoute>} />
    </Routes>
  );
}

export default App;
*/}
return(<div className="App">
     {mainscreen?  <MainLayout
     email={email}
     setEmail={setEmail}/>
     :
     <Login 
     email={email}
     setEmail={setEmail}
     mainscreen={mainscreen}
     setMainscreen={setMainscreen}/>}
    </div>)
}

//export default withAuthenticator(App);

export default App;