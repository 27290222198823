import { useMap } from "../../hooks/useMap";
import { GeoJSON } from "ol/format";
import { vector } from "./Mapping/Source";
import VectorLayer from "ol/layer/Vector";
import { useEffect, useState } from "react";
import { calculateArea, removeLayerByName } from "../../lib/gis";
import { fromExtent } from "ol/geom/Polygon";
import VisualDataset from "./VisualDataset";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { changeDistrictName, changeLevel1Name, changeLevel2Name, changeLevel3Name, changeLevel4Name, changeLevel5Name, changeStateName } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import shp from "shpjs";
import { FaUpload } from "react-icons/fa6";
import { Auth } from "aws-amplify";


function UploadAShape(props) {
  const { map } = useMap();
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const [exploreDataset, setExploreDataset] = useState(false);
  const [uploadedArea, setUploadedArea] = useState();
  const [isReset, setIsReset] = useState(false);
  const [exploreDatasetDisabled, setExploreDatasetDisabled] = useState(true);
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const level4Data = useSelector((state) => state.level4Data);
  const level3Data = useSelector((state) => state.level3Data);
  const level5Data = useSelector((state) => state.level5Data);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map) return;
    return () => {
      if (map) {
        removeLayerByName(map, "uploadedLayer");
        setUploadedArea();
      }
    };
  }, []);



  const handleFileUpload = async (event) => {
    setExploreDatasetDisabled(true);
    props.setCountryName("");
    dispatch(changeStateName(null));
    dispatch(changeDistrictName(null));
    dispatch(changeLevel3Name(null));
    dispatch(changeLevel4Name(null));
    dispatch(changeLevel5Name(null));
    const files = event.target.files;
    if (!files || files.length === 0) {
      toast.error("No file selected.");
      return;
    }

    let geojson = null;

    // Handle GeoJSON and JSON Files
    if (files.length === 1) {
      const file = files[0];
      const ext = file.name.split(".").pop().toLowerCase();

      if (ext === "geojson" || ext === "json") {
        const reader = new FileReader();
        reader.onload = (e) => {
          try {
            geojson = JSON.parse(e.target.result);
            processGeoJSON(geojson);
          } catch (error) {
            toast.error("Invalid GeoJSON file.");
            console.error("GeoJSON Parsing Error:", error);
          }
        };
        reader.readAsText(file);
        return;
      }
    }

    // Handle Shapefile (.shp, .shx, .dbf)
    let fileMap = {};
    for (let file of files) {
      const ext = file.name.split(".").pop().toLowerCase();
      fileMap[ext] = file;
    }

    if (!fileMap["shp"] || !fileMap["shx"] || !fileMap["dbf"]) {
      toast.error("Missing required files. Upload .shp, .shx, and .dbf together.");
      return;
    }

    // Read shapefile components
    const fileReaders = {};
    for (let ext in fileMap) {
      fileReaders[ext] = new FileReader();
    }

    const fileReadPromises = Object.keys(fileReaders).map(
      (ext) =>
        new Promise((resolve) => {
          fileReaders[ext].onload = (e) => resolve({ ext, data: e.target.result });
          fileReaders[ext].readAsArrayBuffer(fileMap[ext]);
        })
    );

    try {
      const fileData = await Promise.all(fileReadPromises);
      const fileBuffers = {};
      fileData.forEach(({ ext, data }) => (fileBuffers[ext] = data));

      geojson = await shp(fileBuffers);
      processGeoJSON(geojson);
    } catch (error) {
      toast.error("Error processing shapefile. Please check your files.");
      console.error("Shapefile Processing Error:", error);
    }
  };

  const processGeoJSON = (geojson) => {
    try {
      const features = new GeoJSON().readFeatures(geojson);

      // Calculate Area
      const area = calculateArea(features[0].getGeometry());
      setUploadedArea(area);

      if (area > 100000000) {
        toast.error("Uploaded area is greater than 100000 ha");
        setIsReset(true);
        return;
      }

      // Add Layer to Map
      const vectorSource = vector({ features });
      const vectorLayer = new VectorLayer({ source: vectorSource });
      vectorLayer.set("name", "uploadedLayer");
      map.addLayer(vectorLayer);
      map.getView().fit(vectorLayer.getSource().getExtent(), {
        padding: [50, 50, 50, 50],
        duration: 1000,
      });
      getLocation(vectorSource);

      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.error("Error processing GeoJSON file.");
      console.error("GeoJSON Processing Error:", error);
    }
  };

  const getLocation = (source) => {
    const extent = source.getExtent();
    const polygon = fromExtent(extent);
    const coordinate = polygon.getInteriorPoint().getCoordinates();
    const lat = parseFloat(coordinate[1])
    const long = parseFloat(coordinate[0])
    setSelectedLocation(getSelectedLocation(long, lat));
    exploreDatasets(lat, long);
  };

  const getSelectedLocation = () => {
    let selectedLocation = "Location";
    if (props.countryName) {
      selectedLocation = props.countryName;
      if (stateName) {
        selectedLocation += `, ${stateName}`;
        if (districtName) {
          selectedLocation += `, ${districtName}`;
          if (level3Data) {
            selectedLocation += `, ${level3Data}`;
            if (level4Data) {
              selectedLocation += `, ${level4Data}`;
            }
            if (level5Data) {
              selectedLocation += `, ${level5Data}`
            }
          }
        }
      }
    }
    return selectedLocation;
  };

  const handleResetFields = () => {
    setExploreDataset(false);
    setSelectedLocation("Location");
    setExploreDatasetDisabled(true);
    props.setCountryName("");
    dispatch(changeStateName(null));
    dispatch(changeDistrictName(null));
    dispatch(changeLevel3Name(null));
    dispatch(changeLevel4Name(null));
    dispatch(changeLevel5Name(null));
  };

  const exploreDatasets = async (lat, long) => {
    props.setCountryName("");
    dispatch(changeStateName(null));
    dispatch(changeDistrictName(null));
    dispatch(changeLevel3Name(null));
    dispatch(changeLevel4Name(null));
    dispatch(changeLevel5Name(null));
    const requestBody = {
      lat: lat,
      lon: long,
    };

    try {
      const {
              accessToken: { jwtToken },
            } = await Auth.currentSession();
      
      const response = await fetch("/api/coords/features", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      const data = await response.json();
      const properties = data.features[0]?.properties || {};
      setExploreDatasetDisabled(false);

      //props.setEntercoordinateLoc(properties);
      if (properties.COUNTRY !== '') {
        props.setCountryName(properties.COUNTRY || "");
      }
      if (properties.NAME_1 !== '') {
        dispatch(changeStateName(properties.NAME_1 || ""));
        dispatch(changeLevel1Name(properties.NAME_1 || ""));

      }
      if (properties.NAME_2 !== '') {
        dispatch(changeDistrictName(properties.NAME_2 || ""));
        dispatch(changeLevel2Name(properties.NAME_2 || ""));

      }
      if (properties.NAME_3 !== '') {
        dispatch(changeLevel3Name(properties.NAME_3 || ""));
      }
      if (properties.NAME_4 !== '') {
        dispatch(changeLevel4Name(properties.NAME_4 || ""))
      }
      // setCountryName(searchLocation.Country || "");
    } catch (error) {
      console.error("Error fetching data:", error);
      setExploreDatasetDisabled(true);
    }
  };


  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          width: "400px",
          padding: "16px",
          textAlign: "initial",
          color: "#21201C",
        }}
      />
      <div className="menuContent">
        <div className="exploretext">
          <img
            src="chevron-left.png"
            alt="location icon"
            style={{ marginRight: 10 }}
            onClick={() => props.showUploadShape(false)}
          />
          Upload a Shape
        </div>
        <div className="exploresecondarytext">Upload your shape file.</div>

        {/* File Input for Multiple Files */}
        <section className="flex flex-col">
          <div
            className="dropzone"
            style={{
              border: "2px dashed #ccc",
              borderRadius: "8px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#f9f9f9",
            }}
          >
            <input
              type="file"
              accept=".shp,.shx,.dbf,.geojson,.json"
              multiple
              onChange={handleFileUpload}
              style={{ display: "none" }} // Hide the default file input
              id="file-input"
            />
            <label htmlFor="file-input" style={{ cursor: "pointer" }}>
              {uploadedArea ? (
                <p className="text-xl w-full h-full">
                  Uploaded File - {uploadedArea.toFixed(2)} ha
                </p>
              ) : (
                <>
                  <span style={{ fontSize: "36px", color: "#ccc" }}>
                    <FaUpload />
                  </span>
                  <p style={{ fontSize: "12px", color: "#aaa" }}>
                    Drag and drop your file or click to upload.
                  </p>
                </>
              )}
            </label>
          </div>
          {uploadedArea && (
            <div style={{ padding: "16px", textAlign: "center", color: "#999" }}>
              Total Area: {uploadedArea.toFixed(2)} ha
            </div>
          )}
        </section>

        <div className="exploredataset-button-container">
          <div>
            <hr className="horizontal-rule" />
            <button className="selectedloc-button">
              <img src="loc-pin.png" alt="icon" style={{ marginRight: 5 }} />
              {getSelectedLocation()}
              {/* {selectedLocation} */}
              <img
                src="tabler-icon-x.svg"
                className="reset-button"
                alt="Reset"
                onClick={handleResetFields}
              />
            </button>
          </div>

          <div>
            <button
              className="exploredataset-button"
              onClick={() => { setExploreDataset(true) }}
              disabled={exploreDatasetDisabled}
            >
              Explore Datasets
            </button>
          </div>
        </div>
      </div>
      {exploreDataset && (
        <VisualDataset
          tamsatDailyGraph={props.tamsatDailyGraph}
          setTamsatDailyGraph={props.setTamsatDailyGraph}
          tamsatMonthlyGraph={props.tamsatMonthlyGraph}
          setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
          chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
          setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
          arc210kmDailyRainfall={props.arc210kmDailyRainfall}
          setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
          modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
          setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
          setExploreDataset={setExploreDataset}
          exploreDataset={exploreDataset}
          landcover={props.landcover}
          setlandcover={props.setlandcover}
          chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
          setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
          era5DailyRainfallGraph={props.era5DailyRainfallGraph}
          setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
          era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
          setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
          era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
          setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
          era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
          setera5MonthlyTemperatureGraph={props.setera5MonthlyTemperatureGraph}
          gsMapDaily={props.gsMapDaily}
          setgsMapDaily={props.setgsMapDaily}
          gsMapMonthly={props.gsMapMonthly}
          setgsMapMonthly={props.setgsMapMonthly}
          sentinal2NDVI={props.sentinal2NDVI}
          setsentinal2NDVI={props.setsentinal2NDVI}
          selectedLocation={selectedLocation}
          countryName={props.countryName}
          setIsDrawerOpen={props.setIsDrawerOpen}
          entercoordinateLoc={props.entercoordinateLoc}
          setforecastedChirpsDailyRainfallGraph={props.setforecastedChirpsDailyRainfallGraph}
        />
      )}
    </>
  );
}

export default UploadAShape;