export function changeCountryName(data) {
  return {
    type: "CHANGE_COUNTRY_NAME",
    payload: { countryName: data },
  };
}
export function changeStateName(data) {
  return {
    type: "CHANGE_STATE_NAME",
    payload: { stateName: data },
  };
}

export function changeStates(data) {
  return {
    type: "CHANGE_States",
    payload: { states: data },
  };
}
export function changeSelectedStates(data) {
  return {
    type: "CHANGE_selectedStates",
    payload: { selectedStates: data },
  };
}

export function changeDistrictName(data) {
  return {
    type: "CHANGE_DISTRICT_NAME",
    payload: { districtName: data },
  };
}
export function changeDistrict(data) {
  return {
    type: "CHANGE_districts",
    payload: { districts: data },
  };
}
export function changeSelectedDistrict(data) {
  return {
    type: "CHANGE_selectedDistricts",
    payload: { selectedDistricts: data },
  };
}
export function changeLevel1Name(data) {
  return {
    type: "CHANGE_LEVEL1DATA_NAME",
    payload: { level1Data: data },
  };
}
export function changeLevel2Name(data) {
  return {
    type: "CHANGE_LEVEL2DATA_NAME",
    payload: { level2Data: data },
  };
}
export function changeLevel3Name(data) {
  return {
    type: "CHANGE_LEVEL3DATA_NAME",
    payload: { level3Data: data },
  };
}
export function changeLevel4Name(data) {
  return {
    type: "CHANGE_LEVEL4DATA_NAME",
    payload: { level4Data: data },
  };
}

export function changeLevel5Name(data) {
  return {
    type: "CHANGE_LEVEL5DATA_NAME",
    payload: { level5Data: data },
  };
}

export function changeIndexName(data) {
  return {
    type: "CHANGE_INDEX_NAME",
    payload: { indexName: data },
  }
}

export function changeLandcoverswitch(data) {
  return {
    type: "CHANGE_LANDCOVER_SWITCH",
    payload: { landcoverswitch: data },
  }
}

export function changeNdvimodisswitch(data) {
  return {
    type: "CHANGE_NDVIMODIS_SWITCH",
    payload: { ndvimodisswitch: data },
  }
}

export function changeZoom(data) {
  return {
    type: "CHANGE_ZOOM",
    payload: { zoom: data },
  }
}
export function changegetStartYears(data) {
  return {
    type: "CHANGE_GET_STARTYEARS",
    payload: { getStartYears: data },
  }
}
export function changegetEndYears(data) {
  return {
    type: "CHANGE_GET_ENDYEARS",
    payload: { getEndYears: data },
  }
}
export function changegetStartMonth(data) {
  return {
    type: "CHANGE_GET_STARTMONTH",
    payload: { getStartMonth: data },
  }
}
export function changegetEndMonth(data) {
  return {
    type: "CHANGE_GET_ENDMONTH",
    payload: { getEndMonth: data },
  }
}
export function changeModisNDVILayer(data) {
  return {
    type: "CHANGE_MODIS_NDVI_LAYER",
    payload: { modisNdviLayer: data },
  }
}
export function changeLandcoverLayer(data) {
  return {
    type: "CHANGE_LANDCOVER_LAYER",
    payload: { landcoverLayer: data },
  }
}

export function changeSentinel2NDVILayer(data) {
  return {
    type: "CHANGE_SENTINEL2_NDVI_LAYER",
    payload: { sentinel2NdviLayer: data },
  }
}

export function changeWeathervariable(data) {
  return {
    type: "CHANGE_WEATHER_Variable",
    payload: { getWeatherVariable: data },
  }
}
export function changefrequency(data) {
  return {
    type: "CHANGE_FREQUENCY",
    payload: { frequency: data },
  }
}
export function changeCombination(data) {
  return {
    type: "CHANGE_COMBINATION",
    payload: { combination: data },
  }
}
export function changeTempDataset(data) {
  return {
    type: "CHANGE_TempDataset",
    payload: { TempDataset: data },
  }
}
export function changeRainfallDataset(data) {
  return {
    type: "CHANGE_RainfallDataset",
    payload: { RainfallDataset: data },
  }
}
export function changeVegetationDataset(data) {
  return {
    type: "CHANGE_VegetationDataset",
    payload: { VegetationDataset: data },
  }
}
export function changeCompareState(data) {
  return {
    type: "CHANGE_COMPARE",
    payload: { compareState: data },
  }
}
export function changePixelID(data) {
  return {
    type: "CHANGE_PIXEL_ID",
    payload: { pixelID: data },
  }
}
export function changePixelName(data) {
  return {
    type: "CHANGE_PIXEL_NAME",
    payload: { pixelName: data },
  }
}
export function changePixel_id(data) {
  return {
    type: "CHANGE_PIXEL_id",
    payload: { pixel_id: data },
  }
}
export function changeLayerName(data) {
  return {
    type: "CHANGE_LAYER_NAME",
    payload: { layerName: data },
  }
}
export function changegetlocationbysearch(data) {
  return {
    type: "CHANGE_GET_LOC",
    payload: { searchLocation: data },
  }
}
export function changeshowModisNDVIDot(data) {
  return {
    type: "CHANGE_showModisNDVIDot",
    payload: { showModisNDVIDot: data },
  }
}
export function changeshowSentinelNDVIDot(data) {
  return {
    type: "CHANGE_showSentinel2NDVIDot",
    payload: { showSentinel2NDVIDot: data },
  }
}
export function changeshowManual(data) {
  return {
    type: "CHANGE_showManual",
    payload: { showManual: data },
  }
}
export function changeStep(data) {
  return {
    type: "CHANGE_Step",
    payload: { step: data },
  }
}
export function changeshowuploadsite(data) {
  return {
    type: "CHANGE_showuploadsite",
    payload: { showuploadsite: data },
  }
}
export function changeCoverName (data){
  return {
    type: "CHANGE_COVER_NAME",
    payload: { coverName : data},
  };
};

export function changeRiskCovered(data){
  return {
    type: "CHANGE_RISK_COVERED",
    payload: { riskCovered : data},
  };
};

export function changeDatasets(data){
  return {
    type: "CHANGE_DATASETS",
    payload: { datasets : data},
  };
};

export function changecoverstartDate(data){
  return {
    type: "CHANGE_START_DATE",
    payload: { coverstartDate : data},
  };
};

export function changecoverendDate (data){
  return {
    type: "CHANGE_END_DATE",
    payload: { coverendDate : data},
  };
};

export function changeWeightage(data) {
  return {
    type: "CHANGE_WEIGHTAGE",
    payload: { weightage: data },
  };
};
export function changeCoverPeriodType(data) {
  return {
    type: "CHANGE_COVER_PERIOD_TYPE",
    payload: { coverPeriodType: data },
  };
};

export function changePhaseCalculation (phaseCalculation){
  return {
    type: "CHANGE_PHASE_CALCULATION",
    payload: { phaseCalculation },
  };
};

export function changeRiskyDayCalculation(riskyDayCalculation) {
  return {
    type: "CHANGE_RISKY_DAY_CALCULATION",
    payload: { riskyDayCalculation },
  };
};

export function changeRiskyDaysTrigger(riskyDaysTrigger) {
  return {
    type: "CHANGE_RISKY_DAYS_TRIGGER",
    payload: { riskyDaysTrigger },
  };
};

export function changeRiskyDaysExit  (riskyDaysExit) {
  return {
    type: "CHANGE_RISKY_DAYS_EXIT",
    payload: { riskyDaysExit },
  };
};
export function changeacrossBlockCalc  (acrossBlockCalc) {
  return {
    type: "CHANGE_ACROSS_BLOCK",
    payload: { acrossBlockCalc },
  };
};
export function changeTypeOfProduct(data) {
  return {
    type: "CHANGE_TYPE_OF_PRODUCT",
    payload: { typeOfProduct: data },
  };
}

export function changeProductName(data) {
  return {
    type:"CHANGE_PRODUCT_NAME",
    payload: { productName: data },
  };
}
export function changeNumberOfCovers(data) {
  return {
    type: "CHANGE_NUMBER_OF_COVERS",
    payload: { numberOfCovers: data },
  };
}

export function changeDeductibleType(data) {
  return {
    type: "CHANGE_DEDUCTIBLE_TYPE",
    payload: { deductibleType: data },
  };
}
export function changeDeductiblePercentage(data) {
  return {
    type: "CHANGE_DEDUCTIBLE_PERCENTAGE",
    payload: { deductiblePercentage: data },
  };
}
export function changePricingMethod(data) {
  return {
    type: "CHANGE_PRICING_METHOD",
    payload: { pricingMethod: data },
  };
}
export function changeMethodofThreshold(data) {
  return {
    type: "CHANGE_MethodofThreshold",
    payload: { MethodofThreshold: data },
  };
}
export function changeweatherTrigger(data) {
  return {
    type: "CHANGE_weatherTrigger",
    payload: { weatherTrigger: data },
  };
}
export function changeweatherExit(data) {
  return {
    type: "CHANGE_weatherExit",
    payload: { weatherExit: data },
  };
}
export function changecoverMaxPayout(data) {
  return {
    type: "CHANGE_coverMaxPayout",
    payload: { coverMaxPayout: data },
  };
}
export function changecoverMinPayout(data) {
  return {
    type: "CHANGE_coverMinPayout",
    payload: { coverMinPayout: data },
  };
}
export function changeTargetLossRatioselection(data) {
  return {
    type: "CHANGE_TargetLossRatioselection",
    payload: { targetLossRatioselection: data },
  };
}
export function changeTargetLossRatioValue(data) {
  return {
    type: "CHANGE_TargetLossRatioValue",
    payload: { targetLossRatioValue: data },
  };
}
export function changecommercialpremiumRateType(data) {
  return {
    type: "CHANGE_commercialpremiumRateType",
    payload: { commercialpremiumRateType: data },
  };
}
export function changeIULevel1(data) {
  return {
    type: "CHANGE_IULevel1",
    payload: { IULevel1: data },
  };
}
export function changeIULevel2(data) {
  return {
    type: "CHANGE_IULevel2",
    payload: { IULevel2: data },
  };
}
export function changeIULevel3(data) {
  return {
    type: "CHANGE_IULevel3",
    payload: { IULevel3: data },
  };
}
export function changeIULevel4(data) {
  return {
    type: "CHANGE_IULevel4",
    payload: { IULevel4: data },
  };
}
export function changeIULevel5(data) {
  return {
    type: "CHANGE_IULevel5",
    payload: { IULevel5: data },
  };
}
export function changeIUList(data) {
  return {
    type: "CHANGE_IUList",
    payload: { IUList: data },
  };
}
export function changeBlockSize(data) {
  return {
    type: "CHANGE_BLOCK_SIZE",
    payload: { blockSize: data },
  };
}

export function changeRiskInterval(data) {
  return {
    type: "CHANGE_RISK_INTERVAL",
    payload: { riskInterval: data },
  };
}

export function changeWithinBlockCalc(data) {
  return {
    type: "CHANGE_WITHIN_BLOCK_CALC",
    payload: { withinBlockCalc: data },
  };
}


export function changeBlockCountCalc(data) {
  return {
    type: "CHANGE_BLOCK_COUNT_CALC",
    payload: { blockCountCalc: data },
  };
}

export function changeBlockCountTrigger(data) {
  return {
    type: "CHANGE_BLOCK_COUNT_TRIGGER",
    payload: { blockCountTrigger: data },
  };
}

export function changeBlockCountExit(data) {
  return {
    type: "CHANGE_BLOCK_COUNT_EXIT",
    payload: { blockCountExit: data },
  };
}
export function changeInsurerMargin(data) {
  return {
    type: "CHANGE_INSURER_MARGIN",
    payload: { insurerMargin: data },
  };
}

export function changeProductDevelopmentFee(data) {
  return {
    type: "CHANGE_PRODUCT_DEVELOPMENT_FEE",
    payload: { productDevelopmentFee: data },
  };
}

export function changeBrokerFee(data) {
  return {
    type: "CHANGE_BROKER_FEE",
    payload: { brokerFee: data },
  };
}

export function changeWithholdingTax(data) {
  return {
    type: "CHANGE_WITHHOLDING_TAX",
    payload: { withholdingTax: data },
  };
}
export function changePremiumLevy(data) {
  return {
    type: "CHANGE_PremiumLevy",
    payload: { PremiumLevy: data },
  };
}
export const updateCoverDetails = (coverDetails) => {
  return {
    type: 'CHANGE_COVER_DETAILS',
    payload: coverDetails
  };
};
export function changeAggregator(data) {
  return {
    type: "CHANGE_AGGREGATOR",
    payload: { aggregator: data },
  };
}

export function changeInsurer(data) {
  return {
    type: "CHANGE_INSURER",
    payload: { insurer: data },
  };
}

export function changeValueChain(data) {
  return {
    type: "CHANGE_VALUE_CHAIN",
    payload: { valueChain: data },
  };
}

export function changeCurrency(data) {
  return {
    type: "CHANGE_CURRENCY",
    payload: { currency: data },
  };
}

export function changeSumInsured(data) {
  return {
    type: "CHANGE_SUM_INSURED",
    payload: { sumInsured: data },
  };
}
export function changemethodofSelection(data) {
  return {
    type: "CHANGE_methodofSelection",
    payload: { methodofSelection: data },
  };
}
export function changeMaxPremium(data) {
  return {
    type: "CHANGE_MaxPremium",
    payload: { MaxPremium: data },
  };
}
export function changeMinPremium(data) {
  return {
    type: "CHANGE_MinPremium",
    payload: { MinPremium: data },
  };
}
export function changeMeanPremium(data) {
  return {
    type: "CHANGE_MeanPremium",
    payload: { MeanPremium: data },
  };
}
export function changeCommercialPremium(data) {
  return {
    type: "CHANGE_CommercialPremium",
    payload: { CommercialPremium: data },
  };
}
export function changeCommercialMaxPremium(data) {
  return {
    type: "CHANGE_CommercialMaxPremium",
    payload: { CommercialMaxPremium: data },
  };
}
export function changeCommercialMinPremium(data) {
  return {
    type: "CHANGE_CommercialMinPremium",
    payload: { CommercialMinPremium: data },
  };
}
export function changeTotalIU(data) {
  return {
    type: "CHANGE_TotalIU",
    payload: { TotalIU: data },
  };
}
export function changeIUAnalysisCommercial(data) {
  return {
    type: "CHANGE_IUAnalysisCommercial",
    payload: { IUAnalysisCommercial: data },
  };
}
export function changeIUAnalysisPremium(data) {
  return {
    type: "CHANGE_IUAnalysisPremium",
    payload: { IUAnalysisPremium: data },
  };
}
export function changeNewIUList(data) {
  return {
    type: "CHANGE_NewIUList",
    payload: { NewIUList: data },
  };
}
export function changeSelectedIU(data) {
  return {
    type: "CHANGE_SelectedIU",
    payload: { SelectedIU: data },
  };
}
export function changeSavedPolicyDetails(data) {
  return {
    type: "CHANGE_policyDetails",
    payload: { policyDetails: data },
  };
}
export function changeEditPolicy(data) {
  return {
    type: "CHANGE_EditPolicy",
    payload: { EditPolicy: data },
  };
}

export function changeIUList2(data) {
  return {
    type: "CHANGE_IUList2",
    payload: { IUList2: data },
  };
}
export function changeIUListdownload(data) {
  return {
    type: "CHANGE_IUListDownload",
    payload: { IUListDownload: data },
  };
}
export function changeEditingState(data) {
  return {
    type: "CHANGE_EditingState",
    payload: { EditingState: data },
  };
}