import { useEffect, useState } from "react";
import { useMap } from "../../hooks/useMap";
import VectorLayer from "ol/layer/Vector";
import { Icon, Style } from "ol/style";
import { Point } from "ol/geom";
import { Feature } from "ol";
import { fromLonLat } from "ol/proj";
import VectorSource from "ol/source/Vector";
import VisualDataset from "./VisualDataset";
import 'react-toastify/dist/ReactToastify.css';
import { changeDistrictName, changeLevel1Name, changeLevel2Name, changeLevel3Name, changeLevel4Name, changeStateName } from "../../redux/action";
import { useDispatch} from "react-redux";
import { Auth } from "aws-amplify";


function EnterCoordinates(props) {
  const { map } = useMap();
  const [lat, setLat] = useState();
  const [long, setLong] = useState();
  const [markerLayer, setMarkerLayer] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState("Location");
  const [exploreDataset, setExploreDataset] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!map) return;
    // Initialize marker layer
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayer({
      source: markerSource,
    });
    map.addLayer(markerLayer);
    setMarkerLayer(markerLayer);

    return () => {
      map.removeLayer(markerLayer);
    };
  }, []);

  const goToLocation = async () => {
    getSelectedLocation([long, lat]);
    // Clear previous markers
    markerLayer.getSource().clear();
    // Add new marker
    const marker = new Feature({
      geometry: new Point(fromLonLat([long, lat])),
    });
    marker.getGeometry().transform("EPSG:3857", "EPSG:4326");
    marker.setStyle(
      new Style({
        image: new Icon({
          src: "/location-pin.png",
        }),
      })
    );
    markerLayer.getSource().addFeature(marker);

    // Center the map on the new marker
    map.getView().animate({ center: [long, lat], zoom: 10, duration: 1000 });
  };

  const getSelectedLocation = async (coordinate) => {
    await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${coordinate[1]}&lon=${coordinate[0]}`
    )
      .then((res) => res.json())
      .then((result) => {
        props.setCountryName(result.address.country);
        setSelectedLocation(result.display_name);
      })
      .catch((err) => console.log(err));
  };

  const handleResetFields = () => {
    setExploreDataset(false);
    setSelectedLocation("Location");
    map.removeLayer(markerLayer);
  };
  const exploreDatasets = async () => {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();

    const requestBody = {
      lat: lat,
      lon: long,
    };
  
    try {
      const response = await fetch("/api/coords/features", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(requestBody),
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
  
      const data = await response.json();
  
      const properties = data.features[0]?.properties || {};
      
      props.setEntercoordinateLoc(properties);
      if (properties.COUNTRY!== '') {
        props.setCountryName(properties.COUNTRY || "");
      }
      if (properties.NAME_1  !== '') {
        dispatch(changeStateName(properties.NAME_1 || ""));
        dispatch(changeLevel1Name(properties.NAME_1  || ""));

      }
      if (properties.NAME_2 !== '') {
        dispatch(changeDistrictName(properties.NAME_2 || ""));
        dispatch(changeLevel2Name(properties.NAME_2 || ""));

      }
      if (properties.NAME_3 !== '') {
        dispatch(changeLevel3Name(properties.NAME_3|| ""));
      }
      if (properties.NAME_4 !== '') {
        dispatch(changeLevel4Name(properties.NAME_4|| ""))
      }
      // setCountryName(searchLocation.Country || "");
   
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  return (
    <>
      <div className="menuContent">
        <div className="exploretext">
          <img
            src="chevron-left.png"
            alt="location icon"
            style={{ marginRight: 10 }}
            onClick={() => props.showEnterCoordinates(false)}
          />
          Enter Coordinates
        </div>
        <div className="exploresecondarytext">
          Enter coordinates of you location.
        </div>
        <div >

          <input
            type="number"
            className="inputArea"
            placeholder="Enter Latitude"
            style={{ marginBottom: "10px" }}
            onChange={(e) => setLat(e.target.value)}
          />
  
         
          <input
            type="number"
            className="inputArea"
            placeholder="Enter Longitude"
            style={{ marginBottom: "10px" }}
            onChange={(e) => setLong(e.target.value)}
          />
      
          <button
            className="button"
            onClick={goToLocation}
            disabled={!lat || !long}
          >
            Go to location
          </button>
        </div>

        <div className="exploredataset-button-container">
          <div>
            <hr className="horizontal-rule" />
            <button className="selectedloc-button">
              <img src="loc-pin.png" alt="icon" style={{ marginRight: 5 }} />
              {selectedLocation}
              <img
                src="tabler-icon-x.svg"
                className="reset-button"
                alt="Reset"
                onClick={handleResetFields}
              />
            </button>
          </div>

          <div>
            <button
              className="exploredataset-button"
              onClick={() =>{
                exploreDatasets();
                 setExploreDataset(true)}}
              disabled={lat!==null && long !== null}
            >
              Explore Datasets
            </button>
          </div>
        </div>
      </div>

      {exploreDataset && (
        <VisualDataset
          tamsatDailyGraph={props.tamsatDailyGraph}
          setTamsatDailyGraph={props.setTamsatDailyGraph}
          tamsatMonthlyGraph={props.tamsatMonthlyGraph}
          setTamsatMonthlyGraph={props.setTamsatMonthlyGraph}
          chirps5kmMonthlyRainfall={props.chirps5kmMonthlyRainfall}
          setChirps5kmMonthlyRainfall={props.setChirps5kmMonthlyRainfall}
          arc210kmDailyRainfall={props.arc210kmDailyRainfall}
          setarc210kmDailyRainfall={props.setarc210kmDailyRainfall}
          modis5DayIntervalNDVIGraph={props.modis5DayIntervalNDVIGraph}
          setModis5DayIntervalNDVIGraph={props.setModis5DayIntervalNDVIGraph}
          setExploreDataset={setExploreDataset}
          exploreDataset={exploreDataset}
          landcover={props.landcover}
          setlandcover={props.setlandcover}
          chirps5kmDailyRainfall={props.chirps5kmDailyRainfall}
          setChirps5kmDailyRainfall={props.setChirps5kmDailyRainfall}
          era5DailyRainfallGraph={props.era5DailyRainfallGraph}
          setera5DailyRainfallGraph={props.setera5DailyRainfallGraph}
          era5MonthlyRainfallGraph={props.era5MonthlyRainfallGraph}
          setera5MonthlyRainfallGraph={props.setera5MonthlyRainfallGraph}
          era5DailyTemperatureGraph={props.era5DailyTemperatureGraph}
          setera5DailyTemperatureGraph={props.setera5DailyTemperatureGraph}
          era5MonthlyTemperatureGraph={props.era5MonthlyTemperatureGraph}
          setera5MonthlyTemperatureGraph={props.setera5MonthlyTemperatureGraph}
          gsMapDaily={props.gsMapDaily}
          setgsMapDaily={props.setgsMapDaily}
          gsMapMonthly={props.gsMapMonthly}
          setgsMapMonthly={props.setgsMapMonthly}
          sentinal2NDVI={props.sentinal2NDVI}
          setsentinal2NDVI={props.setsentinal2NDVI}
          selectedLocation={selectedLocation}
          countryName={props.countryName}
          setIsDrawerOpen={props.setIsDrawerOpen}
          entercoordinateLoc={props.entercoordinateLoc}
        />
      )}
    </>
  );
}

export default EnterCoordinates;
