import React, { useState, useEffect } from "react";
import { IoSearchOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import "../../PricingAnalytics.css"; // Import the CSS file for styling
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { FaCaretDown } from "react-icons/fa";
import Checkbox from "@mui/material/Checkbox";
import {
  TextField,
  Select,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  changeDeductiblePercentage,
  changeDeductibleType,
  changeEditPolicy,
  changeIUAnalysisPremium,
  changeIULevel1,
  changeIULevel2,
  changeIULevel3,
  changeIULevel4,
  changeIULevel5,
  changeIULevel6,
  changeIUList,
  changeMaxPremium,
  changeMeanPremium,
  changeMinPremium,
  changeNumberOfCovers,
  changePricingMethod,
  changeProductName,
  changeSelectedStates,
  changeStates,
  changeStep,
  changeTotalIU,
  changeTypeOfProduct,
  changemethodofSelection,
  changeshowManual,
} from "../../redux/action";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import Cover1 from "./Cover1";
import Cover2 from "./Cover2";
import "react-toastify/dist/ReactToastify.css";
import { Auth } from "aws-amplify";

const useStyles = makeStyles({
  slider: {
    color: "rgba(247, 104, 8, 1)",
    width: "100%",
    height: "6px",
    outline: "none",
    opacity: "0.7",
    transition: "opacity .2s",
    borderRadius: "10px",
    marginTop: "20px", // Set the color of the slider
  },
  "&.MuiFormControlLabel-root .MuiFormControlLabel-label": {
    fontSize: "12px",
  },
});

const countrynames = ["Bangladesh"];
const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    width: "90%",
  },
  container2: {
    display: "flex",
    justifyContent: "space-between",
    padding: 5,
    width: "60%",
  },
  container3: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "100%",
  },
  container4: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "33%",
  },
  container5: {
    display: "flex",
    justifyContent: "space-between",
    padding: 2,
    width: "66%",
  },
  container6: {
    display: "flex",

    padding: 5,
    width: "60%",
    justifyContent: "flex-start",
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: 50,
  },
  fieldContainer1: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginRight: 50,
    width: "15%",
  },
  heading: {
    color: "black",
    marginBottom: 5,
    textAlign: "left",
    fontSize: "16px",
    lineHeight: "26px",
    FontFace: "Inter",
    letterSpacing: "-0.04px",
  },
  select: {
    borderRadius: 5,
    height: 40,
  },
  textField: {
    borderRadius: 5,
    height: 30,
  },
  hr: {
    width: "100%",
    border: "none",
    borderTop: "1px solid #ccc",
    margin: "20px 0",
  },
  slider: {
    width: "100%",
    height: "6px",
    outline: "none",
    opacity: "0.7",
    transition: "opacity .2s",
    borderRadius: "10px",
    marginTop: "20px",
  },
  sliderValue: {
    textAlign: "center",
    padding: "6px",
    borderRadius: "5px",
    width: "60px",
    border: "1px solid black",
  },
};

const OrangeRadio = styled(Radio)({
  "&.Mui-checked": {
    color: "rgba(247, 104, 8, 1)",
  },
});

const Manual = (props) => {
  const classes = useStyles();
  const [selectAllStates, setSelectAllStates] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectAllDistrict, setselectAllDistrict] = useState(false);
  const [states, setStates] = useState([]);
  const stateName = useSelector((state) => state.stateName);
  const [districts, setDistricts] = useState([]);
  const [level4, setLevel4] = useState([]);
  const [selectedLevel4, setSelectedLevel4] = useState([]);
  const [selectAllLevel4, setSelectAllLevel4] = useState(false);
  const [level5, setLevel5] = useState([]);
  const [selectedLevel5, setSelectedLevel5] = useState([]);
  const [level6, setLevel6] = useState([]);
  const [selectedLevel6, setSelectedLevel6] = useState([]);
  const [selectAllLevel5, setSelectAllLevel5] = useState(false);
  const [selectAllLevel6, setSelectAllLevel6] = useState(false);
  const districtName = useSelector((state) => state.districtName);
  const step = useSelector((state) => state.step);
  const [countrySearch, setCountrySearch] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [stateSearch, setStateSearch] = useState("");
  const [districtSearch, setDistrictSearch] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedDistricts, setSelectedDistricts] = useState([]);
  const [level2_values, setlevel2_values] = useState({});
  const typeOfProduct = useSelector((state) => state.typeOfProduct);
  const productName = useSelector((state) => state.productName);
  const numberOfCovers = useSelector((state) => state.numberOfCovers);
  const deductibleType = useSelector((state) => state.deductibleType);
  const deductiblePercentage = useSelector(
    (state) => state.deductiblePercentage
  );
  const dispatch = useDispatch();
  const methodofSelection = useSelector((state) => state.methodofSelection);
  const acrossBlockCalc = useSelector((state) => state.acrossBlockCalc);
  const coverName = useSelector((state) => state.coverName);
  const riskCovered = useSelector((state) => state.riskCovered);
  const datasets = useSelector((state) => state.datasets);
  const coverstartDate = useSelector((state) => state.coverstartDate);
  const coverendDate = useSelector((state) => state.coverendDate);
  const weightage = useSelector((state) => state.weightage);
  const coverPeriodType = useSelector((state) => state.coverPeriodType);
  const phaseCalculation = useSelector((state) => state.phaseCalculation);
  const pricingMethod = useSelector((state) => state.pricingMethod);
  const IULevel1 = useSelector((state) => state.IULevel1);
  const IULevel2 = useSelector((state) => state.IULevel2);
  const IULevel3 = useSelector((state) => state.IULevel3);
  const IULevel4 = useSelector((state) => state.IULevel4);
  const IUList = useSelector((state) => state.IUList);
  const weatherExit = useSelector((state) => state.weatherExit);
  const weatherTrigger = useSelector((state) => state.weatherTrigger);
  const MethodofThreshold = useSelector((state) => state.MethodofThreshold);
  const coverMaxPayout = useSelector((state) => state.coverMaxPayout);
  const coverMinPayout = useSelector((state) => state.coverMinPayout);
  const [error, setError] = useState("");
  const blockSize = useSelector((state) => state.blockSize);
  const riskInterval = useSelector((state) => state.riskInterval);
  const withinBlockCalc = useSelector((state) => state.withinBlockCalc);
  const blockCountCalc = useSelector((state) => state.blockCountCalc);
  const blockCountTrigger = useSelector((state) => state.blockCountTrigger);
  const blockCountExit = useSelector((state) => state.blockCountExit);
  const [productExists, setProductExists] = useState(null);
  const coverDetails = useSelector((state) => state.coverDetails);
  const [saved, setSaved] = useState(false);
  const NewIUList = useSelector((state) => state.NewIUList);
  const policyDetails = useSelector((state) => state.policyDetails);
  const insurer = useSelector((state) => state.insurer);
  const valueChain = useSelector((state) => state.valueChain);
  const aggregator = useSelector((state) => state.aggregator);
  const currency = useSelector((state) => state.currency);
  const sumInsured = useSelector((state) => state.sumInsured);
  const commercialpremiumRateType = useSelector(
    (state) => state.commercialpremiumRateType
  );
  const [cover1rows, setcover1Rows] = useState([]);
  const [cover2rows, setcover2Rows] = useState([]);
  const EditPolicy = useSelector((state) => state.EditPolicy);
  const EditingState = useSelector((state) => state.EditingState);

  useEffect(() => {
    if (policyDetails && policyDetails.length > 0) {
      const policy = policyDetails[0];
      dispatch(changeTypeOfProduct(policy.product_type || ""));
      dispatch(changeProductName(policy.product_name || ""));
      dispatch(changeNumberOfCovers(policy.number_of_covers || 0));
      dispatch(changeDeductibleType(policy.deductible_type || ""));
      const roundedDeductiblePercent = (
        (policy.deductible_percent || 0) * 100
      ).toFixed(2);
      dispatch(changeDeductiblePercentage(roundedDeductiblePercent));
      dispatch(changePricingMethod(policy.pricing_method || ""));

      const states = [];
      const districts = [];
      const level4 = [];
      const level5 = [];
      const level6 = []

      policyDetails.forEach((policy) => {
        if (policy.level1) states.push(policy.level1);
        if (policy.level2) districts.push(policy.level2);
        if (policy.level3) level4.push(policy.level3);
        if (policy.level4) level5.push(policy.level4);
        if (policy.level5) level5.push(policy.level5);
      });

      // Remove duplicates
      const uniqueStates = [...new Set(states)];
      const uniqueDistricts = [...new Set(districts)];
      const uniqueLevel4 = [...new Set(level4)];
      const uniqueLevel5 = [...new Set(level5)];
      const uniqueLevel6 = [...new Set(level6)];

      // Set the selected values for country and hierarchical levels
      setSelectedCountry(policy.country || "");
      setSelectedStates(uniqueStates);
      setSelectedDistricts(uniqueDistricts);
      setSelectedLevel4(uniqueLevel4);
      setSelectedLevel5(uniqueLevel5);
      setSelectedLevel6(uniqueLevel6);
      const Levels = [];
      policyDetails.map((policy, index) => {
        Levels[index] = Levels[index] || {};
        Levels[index]["level1"] = policy.level1 || null;
        Levels[index]["level2"] = policy.level2 || null;
        Levels[index]["level3"] = policy.level3 || null;
        Levels[index]["level4"] = policy.level4 || null;
        Levels[index]["level5"] = policy.level5 || null;
      });

      dispatch(changeIUList(Levels));
    }
  }, [JSON.stringify(policyDetails)]);

  useEffect(() => {
    if (policyDetails && policyDetails.length > 0) {
      const policy = policyDetails[0] || {};

      if (
        typeOfProduct !== policy.product_type ||
        productName !== policy.product_name ||
        numberOfCovers !== policy.number_of_covers ||
        deductibleType !== policy.deductible_type ||
        // deductiblePercentage !== policy.deductible_percent * 100 ||
        pricingMethod !== policy.pricing_method
      ) {
        dispatch(changeEditPolicy(true));
      } else {
        dispatch(changeEditPolicy(false));
      }
    }
  }, [
    typeOfProduct,
    productName,
    numberOfCovers,
    policyDetails,
    deductibleType,
    deductiblePercentage,
    pricingMethod,
    selectedCountry,
    selectedStates,
    selectedDistricts,
    selectedLevel4,
    selectedLevel5,
    selectedLevel6,
  ]);

  const handleDeductibleChange = (event) => {
    const value = event.target.value;
    if (value > 100) {
      setError("Percentage value cannot be greater than 100");
    } else {
      setError("");
      dispatch(changeDeductiblePercentage(value));
    }
  };

  const handleSaveClick = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const email = user.attributes.email;
    const cover1Weightage = parseFloat(weightage ? weightage : 0);
    const cover2Weightage = parseFloat(
      coverDetails.cover2_weightage ? coverDetails.cover2_weightage : 0
    );
    dispatch(changeEditPolicy(true));
    if (
      parseInt(numberOfCovers) > 1 &&
      cover1Weightage + cover2Weightage > 100
    ) {
      alert("Total weightage of covers cannot exceed 100.");
      return;
    }

    try {
      let requestBody = {
        email: email,
        country: "Bangladesh",
        product_type: typeOfProduct,
        product_name: productName,
        number_of_covers: parseInt(numberOfCovers),
        iu_selection_method: "Administrative",
        cover_1_dataset_type: coverDetails.datasets,
        cover_1_risk_type: coverDetails.riskCovered,
        cover_1_start_date: coverDetails.coverstartDate,
        cover_1_end_date: coverendDate,
        deductible_percent: parseInt(
          deductiblePercentage ? deductiblePercentage : 0
        ),
        cover_1_threshold_calculation_method: MethodofThreshold,
        cover_1_block_size: parseInt(blockSize),
        cover_1_risk_interval: parseFloat(riskInterval),
        cover_1_weightage: parseInt(weightage ? weightage : 0),
        cover_1_max_payout: parseInt(coverMaxPayout ? coverMaxPayout : 0),
        cover_1_min_payout: parseInt(coverMinPayout ? coverMinPayout : 0),
        cover_1_period_type: coverPeriodType,
        cover_1_within_block_method: withinBlockCalc,
        cover_1_across_block_method: acrossBlockCalc,
        cover_1_phase_calculation_type: phaseCalculation
          ? phaseCalculation
          : "Cumulative",
        deductible_type: deductibleType,
        pricing_method: pricingMethod,
        list_of_iu_params: NewIUList,
        aggregator: aggregator,
        insurer: insurer,
        value_chain: valueChain,
        currency: currency,
        sum_insured: sumInsured,
        commercial_premium_rate_type: commercialpremiumRateType,
        cover_1_name: coverName,
      };

      // Conditionally add cover_2 parameters if numberOfCovers is 2
      if (parseInt(numberOfCovers) === 2) {
        requestBody = {
          ...requestBody,
          cover_2_dataset_type: coverDetails.cover2_datasets,
          cover_2_risk_type: coverDetails.cover2_riskCovered,
          cover_2_start_date: coverDetails.cover2_startDate,
          cover_2_end_date: coverDetails.cover2_endDate,
          cover_2_threshold_calculation_method:
            coverDetails.cover2_MethodofThresholdCalc,
          cover_2_block_size: parseInt(
            coverDetails.cover2_blockSize ? coverDetails.cover2_blockSize : 1
          ),
          cover_2_risk_interval: parseFloat(
            coverDetails.cover2_riskInterval
              ? coverDetails.cover2_riskInterval
              : 0
          ),
          cover_2_weightage: parseFloat(
            coverDetails.cover2_weightage ? coverDetails.cover2_weightage : 0
          ),
          cover_2_max_payout: parseInt(
            coverDetails.cover2_maximumPayout
              ? coverDetails.cover2_maximumPayout
              : 0
          ),
          cover_2_min_payout: parseInt(
            coverDetails.cover2_minimumPayout
              ? coverDetails.cover2_minimumPayout
              : 0
          ),
          cover_2_period_type: coverDetails.cover2_coverperiodtype,
          cover_2_within_block_method: coverDetails.cover2_withinBlockCalc,
          cover_2_across_block_method: coverDetails.cover2_acrossBlockCalc,
          cover_2_phase_calculation_type: coverDetails.cover2_phaseCalculation,
          cover_2_name: coverDetails.cover2Name,
        };
      }

      const apiEndpoint = EditingState
        ? "/api/pricing/editPolicyInput"
        : "/api/pricing/createPolicyInput";
      const policyResponse = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (policyResponse.ok) {
        setSaved(true);
        toast.success(
          <div>
            <strong>Saved Successfully</strong>
            <div style={{ fontSize: "12px", fontWeight: "400" }}>
              Pricing Input Values have been saved successfully
            </div>
          </div>
        );
      }

      const policyData = await policyResponse.json();
    } catch (error) {
      console.error("Error:", error.message);
    }
  };

  const handleCoverChange = (event) => {
    dispatch(changeNumberOfCovers(event.target.value));
  };

  const handlemethodofselectionChange = (event) => {
    dispatch(changemethodofSelection(event.target.value));
  };
  const handleChange = (event) => {
    dispatch(changeDeductibleType(event.target.value));
  };

  useEffect(() => {
    async function genToken() {
      // const {
      //   accessToken: { jwtToken },
      // } = await Auth.currentSession();
      const newLevel2Values = {};
      selectedStates.forEach((state) => {
        newLevel2Values[state] = selectedDistricts.filter((district) =>
          districts[state]?.includes(district)
        );
      });
      setlevel2_values(newLevel2Values);
      let levelName = "";
      if (selectedCountry === "Tanzania") {
        levelName = "state";
      } else if (selectedCountry === "Zambia") {
        levelName = "province";
      } else if (selectedCountry === "Bangladesh") {
        levelName = "division";
      } else if (selectedCountry === "Sudan") {
        levelName = "state";
      } else if (selectedCountry === "Kenya") {
        levelName = "county";
      }
      else if (selectedCountry === "Zimbabwe") {
        levelName = "province";
      }
      else if (selectedCountry === "Rwanda") {
        levelName = "province";
      }
      else if (selectedCountry === "Uganda"||selectedCountry === "Malawi"||selectedCountry === "Ethiopia") {
        levelName = "region";
      }
      else if (selectedCountry === "Cameroon") {
        levelName = "region";
      }
      else if (selectedCountry === "DRC") {
        levelName = "province";
      }
      if (selectedCountry != null) {
        fetch(
          `/api/explore/selectLocation?country=${selectedCountry}&level=${levelName}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              // Authorization: jwtToken,
            },
          }
        )
          .then((response) => response.json())
          .then((data) => {
            setStates(data);
            if (
              selectedStates != null &&
              (selectedCountry === "Tanzania" ||
                selectedCountry === "Zambia" ||
                selectedCountry === "Bangladesh" ||
                selectedCountry === "Kenya" ||
                selectedCountry == "Sudan"||
                selectedCountry === "Zimbabwe"||
                selectedCountry === "Rwanda"||
                selectedCountry === "Uganda"||
                selectedCountry === "Cameroon"||
                selectedCountry === "DRC"||
                selectedCountry === "Malawi"||
                selectedCountry === "Ethiopia")
            ) {
              let level2Name = " ";
              if (
                selectedCountry === "Tanzania" ||
                selectedCountry === "Zambia" ||
                selectedCountry == "Sudan" ||
                selectedCountry === "Zimbabwe"||
                selectedCountry === "Rwanda" ||
                selectedCountry === "Uganda"||
                selectedCountry === "Malawi"
                
              ) {
                level2Name = "district";
              } else if (selectedCountry === "Bangladesh") {
                level2Name = "zila";
              } else if (selectedCountry === "Kenya") {
                level2Name = "constituency";
              }
              else if (selectedCountry === "Cameroon") {
                level2Name = "division";
              }
              else if (selectedCountry === "DRC") {
                level2Name = "territory";
              }
              else if (selectedCountry === "Ethiopia")
              {
                  level2Name = "zone";
              }
              fetch(`/api/downloads/getMultipleLevel2Values`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  // Authorization: jwtToken,
                },
                body: JSON.stringify({
                  country: selectedCountry,
                  level1: selectedStates,
                }),
              })
                .then((response) => response.json())
                .then((data) => {
                  setDistricts(data);
                });
              if (
                selectedDistricts !== null &&
                (selectedCountry === "Tanzania" ||
                  selectedCountry === "Zambia" ||
                  selectedCountry === "Bangladesh" ||
                  selectedCountry === "Kenya" ||
                  selectedCountry === "Sudan"|| 
                  selectedCountry === "Zimbabwe"|| 
                  selectedCountry === "Rwanda"||
                  selectedCountry === "Uganda"||
                  selectedCountry === "Cameroon"||
                  selectedCountry === "Malawi"||
                  selectedCountry === "Ethiopia")
              ) {
                let level3Name = "";
                if (
                  selectedCountry === "Tanzania" ||
                  selectedCountry === "Kenya" ||
                  selectedCountry === "Sudan" ||
                  selectedCountry === "Zimbabwe"
                ) {
                  level3Name = "ward";
                } else if (selectedCountry === "Zambia") {
                  level3Name = "block";
                } else if (selectedCountry === "Bangladesh") {
                  level3Name = "upazila";
              } else if (selectedCountry === "Uganda") {
                level3Name = "county";
              } else if (selectedCountry === "Cameroon") {
                level3Name = "sub-division";
              }
              else if (selectedCountry === "Malawi") {
                level3Name = "sub-district";
              }
              else if (selectedCountry === "Ethiopia") {
                level3Name = "district";
              }
                else if (selectedCountry === "Rwanda") {
                  level3Name = "sector";
                }
                fetch(`/api/downloads/getMultipleLevel3Values`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                    // Authorization: jwtToken,
                  },
                  body: JSON.stringify({
                    country: selectedCountry,
                    level1: selectedStates,
                    level2: selectedDistricts,
                  }),
                })
                  .then((response) => response.json())
                  .then((data) => {
                    setLevel4(data);
                    //  setDistrictDisabled(false);
                    if (
                      selectedLevel4 != null &&
                      (selectedCountry === "Zambia" ||
                        selectedCountry === "Bangladesh"||selectedCountry === "Rwanda"||selectedCountry === "Uganda")
                    ) {
                      let level4Name = "";
                      if (selectedCountry === "Zambia") {
                        level4Name = "camp";
                      } else if (selectedCountry === "Bangladesh") {
                        level4Name = "union";
                      }
                      else if (selectedCountry === "Rwanda") {
                        level4Name = "sector";
                      }
                       else if (selectedCountry === "Uganda") {
                        level4Name = "sub-county";
                      }
                      fetch(`/api/downloads/getMultipleLevel4Values`, {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                          // Authorization: jwtToken,
                        },
                        body: JSON.stringify({
                          country: selectedCountry,
                          level1: selectedStates,
                          level2: selectedDistricts,
                          level3: selectedLevel4,
                        }),
                      })
                        .then((response) => response.json())
                        .then((data) => {
                          setLevel5(data);
                          // setLevel4Disabled(false);
                          if (
                            selectedLevel5 != null &&
                            (selectedCountry === "Rwanda")
                          ) {
                            let level5Name = "";
                           if (selectedCountry === "Rwanda") {
                              level5Name = "cell";
                            }
                            fetch(`/api/downloads/getMultipleLevel5Values`, {
                              method: "POST",
                              headers: {
                                "Content-Type": "application/json",
                                // Authorization: jwtToken,
                              },
                              body: JSON.stringify({
                                country: selectedCountry,
                                level1: selectedStates,
                                level2: selectedDistricts,
                                level3: selectedLevel4,
                                level4: selectedLevel5
                              }),
                            })
                              .then((response) => response.json())
                              .then((data) => {
                                setLevel6(data);
                                // setLevel4Disabled(false);
                              });
                          }
                        });
                    }
                  });
              }
            }
          });
      }

      // });
    }
    genToken();
  }, [
    selectedCountry,
    districtName,
    stateName,
    selectedStates,
    selectedDistricts,
    selectedLevel4,
    selectedLevel5,
    selectAllLevel6,
    JSON.stringify(policyDetails),
  ]);

  const isButtonDisabled = () => {
    const baseCondition =
      typeOfProduct &&
      productName &&
      numberOfCovers &&
      pricingMethod &&
      IULevel1 &&
      IULevel2 &&
      deductibleType &&
      coverName &&
      riskCovered &&
      datasets &&
      coverstartDate &&
      coverendDate &&
      weightage &&
      coverPeriodType &&
      MethodofThreshold &&
      coverMinPayout &&
      coverMaxPayout &&
      weatherExit &&
      weatherTrigger;

    if (!baseCondition) {
      return true;
    }

    if (coverPeriodType === "Block") {
      if (!(blockSize && riskInterval && withinBlockCalc && acrossBlockCalc)) {
        return true;
      }

      if (acrossBlockCalc === "Count") {
        if (!(blockCountCalc && blockCountTrigger && blockCountExit)) {
          return true;
        }
      }
    }

    return false;
  };
  const handlecalculateclick = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const email = user.attributes.email;
    if (!EditPolicy && EditingState) {
      dispatch(changeStep(2));
      props.setLoading(true);
      try {
        const showPurePremiumResponse = await fetch(
          "/api/pricing/showPurePremium",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              country: "Bangladesh",
              product_type: typeOfProduct,
              product_name: productName,
              iu_list: IUList,
            }),
          }
        );

        if (showPurePremiumResponse.status !== 200) {
          throw new Error("Failed to fetch pure premium data");
        }

        const purePremiumData = await showPurePremiumResponse.json();
        dispatch(changeMeanPremium(purePremiumData["mean_premium"]));
        dispatch(changeMinPremium(purePremiumData["min_premium"]));
        dispatch(changeMaxPremium(purePremiumData["max_premium"]));
        dispatch(changeTotalIU(purePremiumData["total_iu"]));
        const showTopIUPurePremiumResponse = await fetch(
          "/api/pricing/showTopIUPurePremium",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              email: email,
              product_type: typeOfProduct,
              product_name: productName,
            }),
          }
        );

        if (showTopIUPurePremiumResponse.status !== 200) {
          throw new Error("Failed to fetch top IU pure premium data");
        }

        const topIUPurePremiumData = await showTopIUPurePremiumResponse.json();
        dispatch(changeIUAnalysisPremium(topIUPurePremiumData));
      } catch (error) {
        console.error("Error:", error);
        toast.error("Error fetching data from server.");
      } finally {
        props.setLoading(false); // Set loading state to false
      }
    }

    if (!saved) {
      toast.error("Please click on Save button first to proceed.");
      return;
    }

    dispatch(changeStep(2));
    props.setLoading(true);

    try {
      const productExistResponse = await fetch("/api/pricing/productExist", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          product_type: typeOfProduct,
          product_name: productName,
        }),
      });

      if (productExistResponse.status !== 200) {
        throw new Error("Failed to fetch product existence data");
      }

      const productExistData = await productExistResponse.json();
      setProductExists(productExistData);

      let requestBody = {
        email: email,
        country: "Bangladesh",
        product_type: typeOfProduct,
        product_name: productName,
        number_of_covers: parseInt(numberOfCovers),
        cover_1_dataset_type: coverDetails.datasets,
        cover_1_risk_type: coverDetails.riskCovered,
        iu_selection_method: "Administrative",
        cover_1_start_date: coverDetails.coverstartDate,
        cover_1_end_date: coverendDate,
        deductible_percent: parseInt(
          deductiblePercentage ? deductiblePercentage : 0
        ),
        cover_1_threshold_calculation_method: MethodofThreshold,
        cover_1_block_size: parseInt(blockSize),
        cover_1_risk_interval: parseFloat(riskInterval),
        cover_1_weightage: parseFloat(weightage ? weightage : 0),
        cover_1_trigger: parseFloat(weatherTrigger ? weatherTrigger : 0),
        cover_1_exit: parseFloat(weatherExit ? weatherExit : 0),
        cover_1_max_payout: parseInt(coverMaxPayout ? coverMaxPayout : 0),
        cover_1_min_payout: parseInt(coverMinPayout ? coverMinPayout : 0),
        cover_1_period_type: coverPeriodType,
        deductible_type: deductibleType,
        pricing_method: pricingMethod,
        cover_1_within_block_method: withinBlockCalc,
        cover_1_across_block_method: acrossBlockCalc,
        cover_1_phase_calculation_type: phaseCalculation
          ? phaseCalculation
          : "Cumulative",
        list_of_iu_params: NewIUList,
        aggregator: aggregator,
        insurer: insurer,
        value_chain: valueChain,
        currency: currency,
        sum_insured: sumInsured,
        commercial_premium_rate_type: commercialpremiumRateType,
        cover_1_name: coverName,
      };

      if (parseInt(numberOfCovers) === 2) {
        requestBody = {
          ...requestBody,
          cover_2_dataset_type: coverDetails.cover2_datasets,
          cover_2_risk_type: coverDetails.cover2_riskCovered,
          cover_2_start_date: coverDetails.cover2_startDate,
          cover_2_end_date: coverDetails.cover2_endDate,
          cover_2_threshold_calculation_method:
            coverDetails.cover2_MethodofThresholdCalc,
          cover_2_block_size: parseInt(
            coverDetails.cover2_blockSize ? coverDetails.cover2_blockSize : 1
          ),
          cover_2_risk_interval: parseFloat(
            coverDetails.cover2_riskInterval
              ? coverDetails.cover2_riskInterval
              : 0
          ),
          cover_2_weightage: parseFloat(
            coverDetails.cover2_weightage ? coverDetails.cover2_weightage : 0
          ),
          cover_2_max_payout: parseInt(
            coverDetails.cover2_maximumPayout
              ? coverDetails.cover2_maximumPayout
              : 0
          ),
          cover_2_min_payout: parseInt(
            coverDetails.cover2_minimumPayout
              ? coverDetails.cover2_minimumPayout
              : 0
          ),
          cover_2_period_type: coverDetails.cover2_coverperiodtype,
          cover_2_within_block_method: coverDetails.cover2_withinBlockCalc,
          cover_2_across_block_method: coverDetails.cover2_acrossBlockCalc,
          cover_2_phase_calculation_type: coverDetails.cover2_phaseCalculation,
          cover_2_name: coverDetails.cover2Name,
        };
      }

      // Call API to calculate pure premium payout
      const calculateResponse = await fetch(
        "/api/pricing/calculatePurePremiumPayout",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (calculateResponse.status !== 200) {
        //dispatch(changeMeanPremium(data["mean_premium"]));
        throw new Error("Failed to calculate pure premium payout");
      }

      const data = await calculateResponse.json();
      dispatch(changeMeanPremium(data["mean_premium"]));
      dispatch(changeMinPremium(data["min_premium"]));
      dispatch(changeMaxPremium(data["max_premium"]));
      dispatch(changeTotalIU(data["total_iu"]));

      const showTopIUPurePremiumResponse = await fetch(
        "/api/pricing/showTopIUPurePremium",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            product_type: typeOfProduct,
            product_name: productName,
          }),
        }
      );

      if (showTopIUPurePremiumResponse.status !== 200) {
        throw new Error("Failed to fetch top IU pure premium data");
      }

      const topIUPurePremiumData = await showTopIUPurePremiumResponse.json();
      dispatch(changeIUAnalysisPremium(topIUPurePremiumData));
    } catch (error) {
      console.error("Error:", error);
      dispatch(changeMeanPremium(0));
      dispatch(changeMinPremium(0));
      dispatch(changeMaxPremium(0));
      dispatch(changeTotalIU(0));
      alert("Something went wrong!!");
      toast.error("Error fetching data from server.");
    } finally {
      props.setLoading(false);
    }
  };

  const handleCountrySearch = (e) => {
    setCountrySearch(e.target.value);
  };

  const handleCountrySelect = (country) => {
    setSelectedCountry(country);
    dispatch(changeSelectedStates([]));
    setSelectedStates([]);
    setSelectedDistricts([]);
    setSelectedLevel4([]);
    setSelectedLevel5([]);
    setSelectedLevel6([]);
  };

  const handleStateSearch = (e) => setStateSearch(e.target.value);

  const handleLevel4CheckboxChange = (level4Option) => {
    const newSelectedLevel4 = selectedLevel4.includes(level4Option)
      ? selectedLevel4.filter((l) => l !== level4Option)
      : [...selectedLevel4, level4Option];
    setSelectedLevel4(newSelectedLevel4);
  };

  const handleLevel5CheckboxChange = (level5Option) => {
    const newSelectedLevel5 = selectedLevel5.includes(level5Option)
      ? selectedLevel5.filter((l) => l !== level5Option)
      : [...selectedLevel5, level5Option];
    setSelectedLevel5(newSelectedLevel5);
  };

  const handleLevel6CheckboxChange = (level6Option) => {
    const newSelectedLevel6 = selectedLevel6.includes(level6Option)
      ? selectedLevel6.filter((l) => l !== level6Option)
      : [...selectedLevel6, level6Option];
    setSelectedLevel6(newSelectedLevel6);
  };

  const handleSelectAllLevel4 = () => {
    if (!selectAllLevel4) {
      const allLevel4 = selectedDistricts.reduce(
        (acc, district) => acc.concat(level4[district] || []),
        []
      );
      setSelectedLevel4(allLevel4);
    } else {
      setSelectedLevel4([]);
    }
    setSelectAllLevel4(!selectAllLevel4);
  };

  const handleSelectAllLevel5 = () => {
    if (!selectAllLevel5) {
      const allLevel5 = selectedLevel4.reduce(
        (acc, level4) => acc.concat(level5[level4] || []),
        []
      );
      setSelectedLevel5(allLevel5);
    } else {
      setSelectedLevel5([]);
    }
    setSelectAllLevel5(!selectAllLevel5);
  };

  const handleSelectAllLevel6 = () => {
    if (!selectAllLevel6) {
      const allLevel6 = selectedLevel5.reduce(
        (acc, level5) => acc.concat(level6[level5] || []),
        []
      );
      setSelectedLevel6(allLevel6);
    } else {
      setSelectedLevel6([]);
    }
    setSelectAllLevel6(!selectAllLevel6);
  };

  const handleDistrictSearch = (e) => setDistrictSearch(e.target.value);

  const handleStateCheckboxChange = (state) => {
    const newSelectedStates = selectedStates.includes(state)
      ? selectedStates.filter((s) => s !== state)
      : [...selectedStates, state];
    dispatch(changeSelectedStates(newSelectedStates));

    setSelectedStates(newSelectedStates);
  };

  const handleDistrictCheckboxChange = (district) => {
    const newSelectedDistricts = selectedDistricts.includes(district)
      ? selectedDistricts.filter((d) => d !== district)
      : [...selectedDistricts, district];
    setSelectedDistricts(newSelectedDistricts);
  };
  const filteredStates = Array.isArray(states)
  ? states.filter((state) =>
      state.toLowerCase().includes(stateSearch.toLowerCase())
    )
  : [];
  const filteredDistricts = (state) =>
    (districts[state] || []).filter((district) =>
      district.toLowerCase().includes(districtSearch.toLowerCase())
    );

  const handleSelectAll = () => {
    if (!selectAllStates) {
      setSelectedStates(states);
      dispatch(changeSelectedStates(states));
    } else {
      setSelectedStates([]);
      dispatch(changeSelectedStates([]));
    }
    setSelectAllStates(!selectAllStates);
  };

  const filteredCountries = countrynames.filter((country) =>
    country.toLowerCase().includes(countrySearch.toLowerCase())
  );

  const handleSelectAllDistrict = () => {
    if (!selectAllDistrict) {
      const allDistricts = selectedStates.reduce(
        (acc, state) => acc.concat(districts[state] || []),
        []
      );
      setSelectedDistricts(allDistricts);
    } else {
      setSelectedDistricts([]);
    }
    setselectAllDistrict(!selectAllDistrict);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleSelectAllFromMenu = () => {
    setSelectedStates(states);
    dispatch(changeSelectedStates(states));
    setselectAllDistrict(districts);
    setSelectAllStates(true);
    setselectAllDistrict(true);
    handleMenuClose();
  };

  const handleClearAllFromMenu = () => {
    dispatch(changeSelectedStates([]));
    setSelectedStates([]);
    setSelectAllStates(false);
    handleMenuClose();
  };

  const updateIUList = () => {
    let list = [];
    if (!policyDetails) {
      if (selectedLevel6.length > 0) {
        list = selectedLevel6.map((level6Option) => {
          const level5Option = Object.keys(level6).find((key) =>
            level5[key]?.includes(level6Option)
          );
          const level4Option = Object.keys(level5).find((key) =>
            level4[key]?.includes(level5Option)
          );
          const district = Object.keys(level4).find((key) =>
            level4[key]?.includes(level4Option)
          );
          const state = Object.keys(districts).find((key) =>
            districts[key]?.includes(district)
          );
          return {
            level1: state,
            level2: district,
            level3: level4Option,
            level4: level5Option,
            level5: level6Option
          };
        });
      }
      else if (selectedLevel5.length > 0) {
        list = selectedLevel5.map((level5Option) => {
          const level4Option = Object.keys(level5).find((key) =>
            level5[key]?.includes(level5Option)
          );
          const district = Object.keys(level4).find((key) =>
            level4[key]?.includes(level4Option)
          );
          const state = Object.keys(districts).find((key) =>
            districts[key]?.includes(district)
          );
          return {
            level1: state,
            level2: district,
            level3: level4Option,
            level4: level5Option,
          };
        });
      } else if (selectedLevel4.length > 0) {
        list = selectedLevel4.map((level4Option) => {
          const district = Object.keys(level4).find((key) =>
            level4[key]?.includes(level4Option)
          );
          const state = Object.keys(districts).find((key) =>
            districts[key]?.includes(district)
          );

          return {
            level1: state,
            level2: district,
            level3: level4Option,
          };
        });
      } else if (selectedDistricts.length > 0) {
        list = selectedDistricts.map((district) => {
          const state = Object.keys(districts).find((key) =>
            districts[key]?.includes(district)
          );

          return {
            level1: state,
            level2: district,
          };
        });
      } else if (selectedStates.length > 0) {
        list = selectedStates.map((state) => ({
          level1: state,
        }));
      }
      //console.log("when new policy is created the list",list)
      dispatch(changeIUList(list));
    } else {
      if (selectedLevel5.length > 0) {
        list = selectedLevel5.map((level5Option) => {
          const level4Option = Object.keys(level5).find((key) =>
            level5[key]?.includes(level5Option)
          );
          const district = Object.keys(level4).find((key) =>
            level4[key]?.includes(level4Option)
          );
          const state = Object.keys(districts).find((key) =>
            districts[key]?.includes(district)
          );
          return {
            level1: state,
            level2: district,
            level3: level4Option,
            level4: level5Option,
          };
        });
      } else if (selectedLevel4.length > 0) {
        list = selectedLevel4.map((level4Option) => {
          const district = Object.keys(level4).find((key) =>
            level4[key]?.includes(level4Option)
          );
          const state = Object.keys(districts).find((key) =>
            districts[key]?.includes(district)
          );

          return {
            level1: state,
            level2: district,
            level3: level4Option,
          };
        });
      } else if (selectedDistricts.length > 0) {
        list = selectedDistricts.map((district) => {
          const state = Object.keys(districts).find((key) =>
            districts[key]?.includes(district)
          );

          return {
            level1: state,
            level2: district,
          };
        });
      } else if (selectedStates.length > 0) {
        list = selectedStates.map((state) => ({
          level1: state,
        }));
      }
      //console.log("when old policy is edited the list",list)
      dispatch(changeIUList(list));
    }
  };

  useEffect(() => {
    dispatch(changeIULevel1(selectedStates));
    dispatch(changeIULevel2(selectedDistricts));
    dispatch(changeIULevel3(selectedLevel4));
    dispatch(changeIULevel4(selectedLevel5));
    dispatch(changeIULevel5(selectedLevel6));
    updateIUList();
  }, [
    selectedStates,
    selectedDistricts,
    selectedLevel4,
    selectedLevel5,
    selectedLevel6,
    level4,
    level5,
    level6
  ]);

  return (
    <>
      <div
        className="pricing-analytics"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="download-pannel">
          <div
            style={{
              textAlign: "left",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "28px",
              letterSpacing: "-0.04px",
            }}
          >
            <img
              src="chevron-left.png"
              alt="location icon"
              style={{ marginRight: 10 }}
              onClick={() => dispatch(changeshowManual(false))}
            />
            Calculate Pricing
            <div
              style={{
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "25px",
                color: "rgba(7, 6, 0, 0.58)",
                marginTop: "10px",
              }}
            >
              Fill the required input fields to calculate pricing
            </div>
          </div>
          <div className="stepcontainer">
            <div
              className={`styled-step ${
                step === 1 ? "active-step" : step > 1 ? "completed-step" : ""
              }`}
            >
              <div className="styled-step-content step">
                <div className="stepNumber">1</div>
                <div>Pricing Inputs</div>
              </div>
            </div>
            <div
              className={`styled-step ${
                step === 2 ? "active-step" : step > 2 ? "completed-step" : ""
              }`}
            >
              <div className="styled-step-content step">
                <div className="stepNumber">2</div>
                <div>Premium Analysis</div>
              </div>
            </div>
            <div
              className={`styled-step ${
                step === 3 ? "active-step" : step > 3 ? "completed-step" : ""
              }`}
            >
              <div className="styled-step-content step">
                <div className="stepNumber">3</div>
                <div>Gross Premium</div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="download-pannel2"
          style={{ width: "100%", background: "rgba(242, 242, 242, 1)" }}
        >
          <div
            className="card"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className="title"
              style={{ display: "flex", flexDirection: "row", padding: 10 }}
            >
              <img
                src="objects.png"
                alt="location icon"
                style={{ marginRight: "18px" }}
              />
              Product Details
            </div>
            <div style={styles.container}>
              <div style={styles.fieldContainer}>
                <div style={styles.heading}>Type of Product</div>
                <FormControl fullWidth>
                  <Select
                    labelId="typeOfProductLabel"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={styles.select}
                    value={typeOfProduct}
                    onChange={(event) =>
                      dispatch(changeTypeOfProduct(event.target.value))
                    }
                  >
                    <MenuItem value="Crop">Crop</MenuItem>
                    <MenuItem value="Livestock">Livestock</MenuItem>
                    <MenuItem value="Area Yield">Area Yield</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div style={styles.fieldContainer}>
                <div style={styles.heading}>Product Name</div>
                <TextField
                  fullWidth
                  id="productType"
                  size="small"
                  style={styles.textField}
                  value={productName}
                  onChange={(event) =>
                    dispatch(changeProductName(event.target.value))
                  }
                />
              </div>
              <div style={styles.fieldContainer}>
                <div style={styles.heading}>No. of Covers</div>
                <TextField
                  fullWidth
                  id="numberOfCover"
                  size="small"
                  style={styles.textField}
                  type="number"
                  value={numberOfCovers}
                  onChange={handleCoverChange}
                />
              </div>
            </div>
            <div style={styles.container}>
              <div style={styles.fieldContainer}>
                <div style={styles.heading}>Deductible Type </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="deductibleType"
                    name="deductibleType"
                    value={deductibleType}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="Franchise"
                      control={<OrangeRadio />}
                      label="Franchise"
                    />
                    <FormControlLabel
                      value="Ordinary"
                      control={<OrangeRadio />}
                      label="Ordinary"
                    />
                    {/* <FormControlLabel value="None" control={<OrangeRadio />} label="None" /> */}
                  </RadioGroup>
                </FormControl>
              </div>
              <div style={styles.fieldContainer}>
                <div style={styles.heading}>Deductible (%) Optional</div>
                <TextField
                  fullWidth
                  id="productType"
                  size="small"
                  style={styles.textField}
                  value={deductiblePercentage}
                  onChange={handleDeductibleChange}
                  error={!!error}
                  helperText={error}
                  type="number"
                  step="0.01"
                />
              </div>
              <div style={styles.fieldContainer}>
                <div style={styles.heading}>Pricing Method</div>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="deductibleType"
                    name="deductibleType"
                    value={pricingMethod}
                    onChange={(event) =>
                      dispatch(changePricingMethod(event.target.value))
                    }
                    style={{ justifyContent: "space-between" }}
                  >
                    <FormControlLabel
                      value="Burn Cost"
                      control={<OrangeRadio />}
                      label="Burn Cost"
                    />
                    <FormControlLabel
                      value="Weighted Average"
                      control={<OrangeRadio />}
                      label="Weighted Average"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>

          <div
            className="card"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className="title"
              style={{ display: "flex", flexDirection: "row", padding: 10 }}
            >
              <img
                src="world.png"
                alt="location icon"
                style={{ marginRight: "18px" }}
              />
              AOI Specifications
            </div>

            <div
              className="search-row"
              style={{
                marginBottom: "10px",
                display: "flex",
                padding: "10px",
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              <div className="searchbar1" style={{ width: "15%" }}>
                <div style={{ position: "relative", marginRight: "10px" }}>
                  <IoSearchOutline
                    style={{
                      position: "absolute",
                      left: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "#777",
                    }}
                  />
                  <input
                    type="text"
                    value={countrySearch}
                    onChange={handleCountrySearch}
                    placeholder="Select Country"
                    style={{
                      paddingLeft: "30px",
                      width: "227px",
                      height: "36px",
                      fontSize: "14px",
                      borderRadius: "8px",
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                    }}
                  />
                </div>

                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "232px" }}
                >
                  {filteredCountries.map((country, index) => (
                    <div
                      key={index}
                      className="list-item"
                      onClick={() => handleCountrySelect(country)}
                      style={{
                        padding: "8px",
                        backgroundColor:
                          selectedCountry === country
                            ? "rgba(255, 108, 3, 0.09)"
                            : "transparent",
                        cursor: "pointer",
                      }}
                    >
                      {country}
                    </div>
                  ))}
                </div>
              </div>

              <div className="searchbar2" style={{ width: "15%" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ position: "relative", marginRight: "4%" }}>
                    <IoSearchOutline
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#777",
                      }}
                    />
                    <input
                      type="text"
                      value={stateSearch}
                      onChange={handleStateSearch}
                      placeholder="Select Division"
                      style={{
                        paddingLeft: "30px",
                        width: "160px",
                        height: "36px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        border: "1px solid rgba(26, 26, 0, 0.16)",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                      gap: "4px",
                      borderRadius: "8px",
                      height: "36px",
                      width: "60px",
                      padding: "8px",
                      display: "flex",
                      alignContent: "end",
                    }}
                  >
                    <IconButton style={{ width: "20px", height: "20px" }}>
                      <Checkbox
                        checked={selectAllStates}
                        onClick={handleSelectAll}
                        sx={{
                          color: "rgba(26, 26, 0, 0.16)",
                          "&.Mui-checked": {
                            color: "#F76808",
                          },
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{ width: "20px", height: "20px", padding: 0 }}
                      onClick={handleMenuOpen}
                    >
                      <FaCaretDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleSelectAllFromMenu}>
                        Select All
                      </MenuItem>
                      <MenuItem onClick={handleClearAllFromMenu}>
                        Clear All
                      </MenuItem>
                    </Menu>
                  </div>
                </div>
                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "231px" }}
                >
                  {filteredStates.map((state, index) => (
                    <div
                      key={index}
                      className="list-item"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "5px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`state-${state}`} // Use `state-${state}` as the id
                        checked={selectedStates.includes(state)}
                        onChange={() => handleStateCheckboxChange(state)}
                        style={{ marginRight: "5px" }}
                      />
                      <label
                        htmlFor={state}
                        style={{
                          cursor: "pointer",
                          color: "black",
                          paddingLeft: "5px",
                        }}
                      >
                        {state}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="searchbar3" style={{ width: "15%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative", marginRight: "4%" }}>
                    <IoSearchOutline
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#777",
                      }}
                    />
                    <input
                      type="text"
                      value={districtSearch}
                      onChange={handleDistrictSearch}
                      placeholder="Select Zila"
                      style={{
                        paddingLeft: "30px",
                        width: "160px",
                        height: "36px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        border: "1px solid rgba(26, 26, 0, 0.16)",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                      gap: "4px",
                      borderRadius: "8px",
                      height: "36px",
                      width: "60px",
                      padding: "8px",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      style={{ width: "20px", height: "20px" }}
                      onClick={handleSelectAllDistrict}
                    >
                      <Checkbox
                        checked={selectAllDistrict}
                        disabled={true}
                        onClick={handleSelectAllDistrict}
                        sx={{
                          color: "rgba(26, 26, 0, 0.16)",
                          "&.Mui-checked": {
                            color: "#F76808",
                          },
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{ width: "20px", height: "20px", padding: 0 }}
                      onClick={handleMenuOpen}
                    >
                      <FaCaretDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleSelectAllDistrict}>
                        Select All
                      </MenuItem>
                      <MenuItem onClick={handleClearAllFromMenu}>
                        Clear All
                      </MenuItem>
                    </Menu>
                  </div>
                </div>

                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "232px" }}
                >
                  {selectedStates.map((state) => (
                    <div key={state} style={{ alignItems: "center" }}>
                      {state}
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {state in districts &&
                          districts[state].map((district, index) => (
                            <li key={index} className="list-item">
                              <input
                                type="checkbox"
                                id={`district-${district}`} // Use `district-${district}` as the id
                                checked={selectedDistricts.includes(district)}
                                onChange={() =>
                                  handleDistrictCheckboxChange(district)
                                }
                                style={{
                                  marginRight: "10px",
                                  marginBottom: "15px",
                                }}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  paddingLeft: "5px",
                                  fontSize: "14px",
                                }}
                                htmlFor={district}
                              >
                                {district}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <div className="searchbar4" style={{ width: "15%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative", marginRight: "4%" }}>
                    <IoSearchOutline
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#777",
                      }}
                    />
                    <input
                      type="text"
                      value={districtSearch}
                      onChange={handleDistrictSearch}
                      placeholder="Select Upazila"
                      style={{
                        paddingLeft: "30px",
                        width: "160px",
                        height: "36px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        border: "1px solid rgba(26, 26, 0, 0.16)",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                      gap: "4px",
                      borderRadius: "8px",
                      height: "36px",
                      width: "60px",
                      padding: "8px",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      style={{ width: "20px", height: "20px" }}
                      onClick={handleSelectAllLevel4}
                    >
                      <Checkbox
                        checked={selectAllLevel4}
                        onClick={handleSelectAllLevel4}
                        sx={{
                          color: "rgba(26, 26, 0, 0.16)",
                          "&.Mui-checked": {
                            color: "#F76808",
                          },
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{ width: "20px", height: "20px", padding: 0 }}
                      onClick={handleMenuOpen}
                    >
                      <FaCaretDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleSelectAllLevel4}>
                        Select All
                      </MenuItem>
                      <MenuItem onClick={handleClearAllFromMenu}>
                        Clear All
                      </MenuItem>
                    </Menu>
                  </div>
                </div>

                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "231px" }}
                >
                  {selectedDistricts.map((district) => (
                    <div key={district} style={{ alignItems: "center" }}>
                      {district}
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {district in level4 &&
                          level4[district].map((level4Option, index) => (
                            <li key={index} className="list-item">
                              <input
                                type="checkbox"
                                id={level4Option}
                                checked={selectedLevel4.includes(level4Option)}
                                onChange={() =>
                                  handleLevel4CheckboxChange(level4Option)
                                }
                                style={{
                                  marginRight: "10px",
                                  marginBottom: "15px",
                                }}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  paddingLeft: "5px",
                                  fontSize: "14px",
                                }}
                                htmlFor={level4Option}
                              >
                                {level4Option}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
              <div className="searchbar5" style={{ width: "15%" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <div style={{ position: "relative", marginRight: "4%" }}>
                    <IoSearchOutline
                      style={{
                        position: "absolute",
                        left: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        color: "#777",
                      }}
                    />
                    <input
                      type="text"
                      value={districtSearch}
                      onChange={handleDistrictSearch}
                      placeholder="Select union"
                      style={{
                        paddingLeft: "30px",
                        width: "160px",
                        height: "36px",
                        fontSize: "14px",
                        borderRadius: "8px",
                        border: "1px solid rgba(26, 26, 0, 0.16)",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid rgba(26, 26, 0, 0.16)",
                      gap: "4px",
                      borderRadius: "8px",
                      height: "36px",
                      width: "60px",
                      padding: "8px",
                      display: "flex",
                    }}
                  >
                    <IconButton
                      style={{ width: "20px", height: "20px" }}
                      onClick={handleSelectAllLevel5}
                    >
                      <Checkbox
                        checked={selectAllLevel5}
                        onClick={handleSelectAllLevel5}
                        sx={{
                          color: "rgba(26, 26, 0, 0.16)",
                          "&.Mui-checked": {
                            color: "#F76808",
                          },
                        }}
                      />
                    </IconButton>
                    <IconButton
                      style={{ width: "20px", height: "20px", padding: 0 }}
                      onClick={handleMenuOpen}
                    >
                      <FaCaretDown />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MenuItem onClick={handleSelectAllLevel5}>
                        Select All
                      </MenuItem>
                      <MenuItem onClick={handleClearAllFromMenu}>
                        Clear All
                      </MenuItem>
                    </Menu>
                  </div>
                </div>

                <div
                  className="list"
                  style={{ textAlign: "left", padding: "10px", width: "231px" }}
                >
                  {selectedLevel4.map((level4) => (
                    <div key={level4} style={{ alignItems: "center" }}>
                      {level4}
                      <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                        {level4 in level5 &&
                          level5[level4].map((level5Option, index) => (
                            <li key={index} className="list-item">
                              <input
                                type="checkbox"
                                id={level5Option}
                                checked={selectedLevel5.includes(level5Option)}
                                onChange={() =>
                                  handleLevel5CheckboxChange(level5Option)
                                }
                                style={{
                                  marginRight: "10px",
                                  marginBottom: "15px",
                                }}
                              />
                              <label
                                style={{
                                  cursor: "pointer",
                                  color: "black",
                                  paddingLeft: "5px",
                                  fontSize: "14px",
                                }}
                                htmlFor={level5Option}
                              >
                                {level5Option}
                              </label>
                            </li>
                          ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div style={styles.container6}>
              <div style={{ marginRight: "100px" }}>Method of IU Selection</div>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="deductibleType"
                  name="deductibleType"
                  value={methodofSelection}
                  onChange={handlemethodofselectionChange}
                >
                  {/* <FormControlLabel
                    value="Clusters (K-means)"
                    control={<OrangeRadio />}
                    label="Clusters (K-means)"
                    disabled
                  /> */}
                  <FormControlLabel
                    value="Administrative"
                    control={<OrangeRadio />}
                    label="Administrative"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </div>

          <div
            className="card"
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "50px",
            }}
          >
            <div
              className="title"
              style={{ display: "flex", flexDirection: "row", padding: 10 }}
            >
              <img
                src="Vector.png"
                alt="location icon"
                style={{ marginRight: "18px" }}
              />
              Cover Details
            </div>
            {numberOfCovers >= 1 && (
              <Cover1
                cover1rows={cover1rows}
                setcover1Rows={setcover1Rows}
                cover2rows={cover2rows}
              />
            )}
            {numberOfCovers >= 2 && (
              <Cover2
                cover2rows={cover2rows}
                setcover2Rows={setcover2Rows}
                cover1rows={cover1rows}
              />
            )}
            {numberOfCovers >= 3 && (
              <Accordion style={{ width: "100%" }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>Cover 3</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Details of Cover 3</Typography>
                </AccordionDetails>
              </Accordion>
            )}
          </div>
        </div>
        <div
          className="row"
          style={{
            position: "fixed",
            bottom: "0",
            right: "0",
            width: "100%",
            backgroundColor: "white",
            zIndex: "1000",
            padding: "15px 20px",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            borderTop: "1px solid #ccc",
          }}
        >
          <div>
            <button
              style={{
                marginRight: "10px",
                padding: "7px 14px",
                backgroundColor: "rgba(241, 245, 249, 1)",
                color: "black",
                border: "1px solid black",
                borderRadius: "5px",
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 500,
                width: "182px",
              }}
              onClick={handleSaveClick}
            >
              Save
            </button>
            <button
              style={{
                padding: "7px 14px",
                backgroundColor: isButtonDisabled()
                  ? "rgba(20, 20, 0, 0.05)"
                  : "black",
                color: isButtonDisabled() ? "rgba(30, 25, 1, 0.24)" : "white",
                border: "none",
                borderRadius: "5px",
                cursor: isButtonDisabled() ? "not-allowed" : "pointer",
                fontSize: "14px",
                fontWeight: 500,
                width: "182px",
                marginRight: "30px",
              }}
              onClick={handlecalculateclick}
              disabled={isButtonDisabled()}
            >
              Proceed
            </button>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              style={{ marginTop: "30px", width: "350px" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Manual;
