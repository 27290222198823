import React, { useState, useEffect } from "react";
import Select from "react-select";
import "../../styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { changeDistrictName, changeLevel3Name, changeLevel4Name, changeStateName, changeLevel1Name, changeLevel2Name, changeWeathervariable, changegetStartYears, changegetEndYears, changegetEndMonth, changegetStartMonth, changefrequency, changeCombination, changeRainfallDataset, changeTempDataset, changeVegetationDataset, changeCompareState } from "../../redux/action";
import { LuRefreshCcw } from "react-icons/lu";

const Compare = (props) => {

  const [states, setStates] = useState([]);
  const stateName = useSelector((state) => state.stateName);
  const [districts, setDistricts] = useState([]);
  const districtName = useSelector((state) => state.districtName);
  const availableCountries = ["Tanzania", "Zambia", "Sudan", "Bangladesh", "Kenya"];
  const [stateDisabled, setStateDisabled] = useState(true);
  const [districtDisabled, setDistrictDisabled] = useState(true);
  const [weatherDisabled, setweatherDisabled] = useState(true);
  const [startYearDisabled, setstartYearDisabled] = useState(true);
  const [EndYearDisabled, setEndYearDisabled] = useState(true);
  const [startMonthDisabled, setstartMonthDisabled] = useState(true);
  const [EndMonthDisabled, setEndMonthDisabled] = useState(true);
  const [frequencyDisabled, setfrequencyDisabled] = useState(true);
  const getStartYears = useSelector((state) => state.getStartYears);
  const getStartMonth = useSelector((state) => state.getStartMonth);
  const [getStartMonthData, setgetStartMonthData] = useState([]);
  const getEndMonth = useSelector((state) => state.getEndMonth);
  const [getEndMonthData, setgetEndMonthData] = useState([]);
  const [getStartYearsData, setgetStartYearsData] = useState([]);
  const [getWeatherVariableData, setgetWeatherVariableData] = useState([]);
  const getWeatherVariable = useSelector((state) => state.getWeatherVariable);
  const getEndYears = useSelector((state) => state.getEndYears);
  const [getEndYearsData, setgetEndYearsData] = useState([]);
  const [frequencyData, setfrequencyData] = useState([]);
  const frequency = useSelector((state) => state.frequency);
  const combination = useSelector((state) => state.combination);
  const compareState = useSelector((state) => state.compareState);

  const dispatch = useDispatch();

  useEffect(() => {
    props.setcomparebutton(false);
    // console.log(props.comparebutton,'from useeffect')
    async function genToken() {
      const {
        accessToken: { jwtToken },
      } = await Auth.currentSession();
      let levelName = "";
      if (props.countryName === "Tanzania") {
        levelName = "state";
      } else if (props.countryName === "Zambia") {
        levelName = "province";
      }
      else if (props.countryName === "Bangladesh") {
        levelName = "division";
      } else if (props.countryName === "Sudan") {
        levelName = "state";
      }
      else if (props.countryName === "Kenya") {
        levelName = "county";
      }
      else if (props.countryName === "Zimbabwe") {
        levelName = "province";
      }
      else if (props.countryName === "Rwanda") {
        levelName = "province";
      }
      else if (props.countryName === "Uganda") {
        levelName = "region";
      }
      else if (props.countryName === "Cameroon"||props.countryName === "Malawi"||props.countryName === "Ethiopia") {
        levelName = "region";
      }
      else if (props.countryName === "DRC") {
        levelName = "province";
      }
      if (props.countryName != null) {
        fetch(`/api/explore/selectLocation?country=${props.countryName}&level=${levelName}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setStates(data);
            setStateDisabled(false);

            if (stateName !== null && (props.countryName === "Tanzania" || props.countryName === "Zambia" || props.countryName === "Bangladesh" || props.countryName === "Kenya" || props.countryName == "Sudan" || props.countryName == "Zimbabwe"|| props.countryName == "Rwanda" || props.countryName == "Uganda"|| props.countryName == "Malawi" || props.countryName == "Ethiopia")) {
              let level2Name = " ";
              if (props.countryName === "Tanzania" || props.countryName === "Zambia" || props.countryName == "Sudan" || props.countryName == "Zimbabwe"|| props.countryName == "Rwanda"|| props.countryName == "Uganda"||props.countryName == "Malawi") {
                level2Name = "district";
              }
              else if (props.countryName === "Bangladesh") {
                level2Name = "zila";
              }
              else if (props.countryName === "Cameroon") {
                level2Name = "division";
              }
              else if (props.countryName === "DRC") {
                level2Name = "territory";
              }
              else if (props.countryName === "Ethiopia") {
                level2Name = "zone";
              }
              else if (props.countryName === "Kenya") {
                level2Name = "constituency";
              }
              fetch(`/api/explore/selectLocation?country=${props.countryName}&${levelName}=${stateName}&level=${level2Name}`, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: jwtToken,
                },
              })
                .then((response) => response.json())
                .then((data) => {
                  setDistricts(data);
                  setDistrictDisabled(false);

                  if (districtName !== null) {
                    fetch("/api/compare/getWeatherVariable", {
                      method: "GET",
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: jwtToken,
                      },
                    })
                      .then((response) => response.json())
                      .then((data) => {
                        setgetWeatherVariableData(data);
                        setweatherDisabled(false);

                        if (getWeatherVariable != null) {
                          fetch("/api/compare/selectStartYear", {
                            method: "GET",
                            headers: {
                              "Content-Type": "application/json",
                              Authorization: jwtToken,
                            },
                          })
                            .then((response) => response.json())
                            .then((data) => {
                              setgetStartYearsData(data);
                              setstartYearDisabled(false);

                              if (getStartYears != null) {
                                fetch("/api/compare/selectEndYear?start_year=" + getStartYears, {
                                  method: "GET",
                                  headers: {
                                    "Content-Type": "application/json",
                                    Authorization: jwtToken,
                                  },
                                })
                                  .then((response) => response.json())
                                  .then((data) => {
                                    setgetEndYearsData(data);
                                    setEndYearDisabled(false)

                                    if (getEndYears !== null) {
                                      fetch("/api/compare/selectStartMonth", {
                                        method: "GET",
                                        headers: {
                                          "Content-Type": "application/json",
                                          Authorization: jwtToken,
                                        },
                                      })
                                        .then((response) => response.json())
                                        .then((data) => {
                                          setgetStartMonthData(data);
                                          setstartMonthDisabled(false)
                                        });
                                      if (getStartMonth !== null) {
                                        fetch("/api/compare/selectEndMonth?start_month=" + getStartMonth, {
                                          method: "GET",
                                          headers: {
                                            "Content-Type": "application/json",
                                            Authorization: jwtToken,
                                          },
                                        })

                                          .then((response) => response.json())
                                          .then((data) => {
                                            setgetEndMonthData(data);
                                            setEndMonthDisabled(false)
                                          });
                                        if (getEndYears !== null) {

                                          fetch("/api/compare/selectFrequency?weather_variable=" + getWeatherVariable, {
                                            method: "GET",
                                            headers: {
                                              "Content-Type": "application/json",
                                              Authorization: jwtToken,
                                            },
                                          })

                                            .then((response) => response.json())
                                            .then((data) => {
                                              setfrequencyData(data);
                                              setfrequencyDisabled(false)
                                            });
                                        }
                                      }
                                    }
                                  });
                              }
                            });
                        }

                      })
                  };
                });

            }
          });
      }


      // });
    }

    genToken();
  }, [props.countryName, districtName, stateName, getWeatherVariable, getStartYears, getEndYears, getStartMonth, getEndMonth, frequency]);
  const handleComparebuttonlick = () => {
    dispatch(changeCompareState(compareState + 1))
    props.setcomparebutton(true);
    props.setshowevalution(true);
  };
  const handleResetbuttonlick = () => {
    props.setCountryName(null);
    dispatch(changeStateName(null));
    dispatch(changeDistrictName(null));
    dispatch(changegetStartYears(null));
    dispatch(changegetEndYears(null));
    dispatch(changegetStartMonth(null));
    dispatch(changegetEndMonth(null));
    dispatch(changeWeathervariable(null));
    dispatch(changefrequency(null));
    // setfrequencyDisabled(true)

  };
  let countryObject = [];
  let stateObject = [];
  let districtObject = [];
  let getStartyearsObject = [];
  let getEndyearsObject = [];
  let getStartMonthsObject = [];
  let getEndMonthsObject = [];
  let getWeatherObject = [];
  let frequencyObject = [];
  availableCountries.forEach((country) => {
    let items = {};
    items["label"] = country;
    items["value"] = country;
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    countryObject.push(json_obj);
  });
  for (let j in states) {
    let items = {};
    items["label"] = states[j];
    items["value"] = states[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    stateObject.push(json_obj);
  }
  for (let j in districts) {
    let items = {};
    items["label"] = districts[j];
    items["value"] = districts[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    districtObject.push(json_obj);
  }
  for (let j in getStartYearsData) {
    let items = {};
    items["label"] = getStartYearsData[j];
    items["value"] = getStartYearsData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getStartyearsObject.push(json_obj);
  }
  for (let j in getEndYearsData) {
    let items = {};
    items["label"] = getEndYearsData[j];
    items["value"] = getEndYearsData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getEndyearsObject.push(json_obj);
  }
  for (let j in getStartMonthData) {
    let items = {};
    items["label"] = getStartMonthData[j];
    items["value"] = getStartMonthData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getStartMonthsObject.push(json_obj);
  }
  for (let j in getEndMonthData) {
    let items = {};
    items["label"] = getEndMonthData[j];
    items["value"] = getEndMonthData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getEndMonthsObject.push(json_obj);
  }
  for (let j in getWeatherVariableData) {
    let items = {};
    items["label"] = getWeatherVariableData[j];
    items["value"] = getWeatherVariableData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    getWeatherObject.push(json_obj);
  }
  for (let j in frequencyData) {
    let items = {};
    items["label"] = frequencyData[j];
    items["value"] = frequencyData[j];
    const jsonString = JSON.stringify(Object.assign({}, items));
    const json_obj = JSON.parse(jsonString);
    frequencyObject.push(json_obj);
  }
  const getLevel2Placeholder = () => {
    if (props.countryName === 'Sudan') {
      return "Select State";
    } else if (props.countryName === 'Tanzania') {
      return "Select State";
    } else if (props.countryName === 'Zambia') {
      return "Select Province";
    } else if (props.countryName === 'Kenya') {
      return "Select County";
    } else if (props.countryName === 'Bangladesh') {
      return "Select Division";
    }
    else if (props.countryName === 'Zimbabwe') {
      return "Select Province";
    }
    else if (props.countryName === 'Uganda'||props.countryName === 'Malawi'||props.countryName === 'Ethiopia') {
      return "Select Region";
    } else if (props.countryName === 'Cameroon') {
      return "Select Region";
    } else if (props.countryName === 'DRC') {
      return "Select Province";
    }
    else if (props.countryName === 'Rwanda') {
      return "Select Province";
    } 
     else {
      return "Select State";
    }
  };
  const getLeveldistrictPlaceholder = () => {
    if (props.countryName === 'Bangladesh') {
      return "Select Zila";
    } else if (props.countryName === 'Kenya') {
      return "Select Constituency";
    } else if (props.countryName === 'Cameroon') {
      return "Select Division";
    } else if (props.countryName === 'DRC') {
      return "Select Territory";
    } else {
      return "Select District";
    }
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? 'orange' : base.borderColor,
      '&:hover': {
        borderColor: state.isFocused ? 'orange' : base.borderColor,
      },
      minHeight: 25,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: 4,
    }),
    valueContainer: (base) => ({
      ...base,
      padding: "0px 6px",
    }),
  };


  return (
    <>
      <div className="compare-pannel">
        <div>
          <div className="exploretext">
            <img src="chevron-left.png" alt="location icon"
              style={{ marginRight: 10 }}
              onClick={() => {
                props.setShowcompare(false)
                props.setshowevalution(false)
              }
              } />
            Compare
          </div>
          <div className="compareecondarytext">
            Make required selections to view available datasets.
          </div>
          <hr className="horizontal-rule" />
          <div className="dropdownlabel">Location </div>
          <div className="download-container">
            <Select
              className="custom-select"
              value={props.countryName ? { label: props.countryName, value: props.countryName } : null}
              onChange={(obj) => {
                props.setCountryName(obj.value);
                // dispatch(changeCountryName(obj["value"]));
                dispatch(changeStateName(null));
                dispatch(changeDistrictName(null));
                dispatch(changeLevel1Name(null));
                dispatch(changeLevel2Name(null));
                // dispatch(changeWeathervariable(null));
                // dispatch(changegetStartYears(null));
                // dispatch(changegetEndYears(null));
                // dispatch(changegetStartMonth(null));
                // dispatch(changegetEndMonth(null));
                // dispatch(changefrequency(null));

              }}
              options={countryObject}
              placeholder="Select Country"
            />
          </div>
          <div className="download-container">
            <Select
              className="custom-select"
              value={{
                value: stateName,
                label: stateName ? stateName : getLevel2Placeholder(props.countryName),
              }}
              options={stateObject}

              onChange={(obj) => {
                dispatch(changeStateName(obj["value"]));
                dispatch(changeDistrictName(null));
                dispatch(changeLevel1Name(obj["value"]));
                dispatch(changeLevel2Name(null));
                // dispatch(changeWeathervariable(null));
                // dispatch(changegetStartYears(null));
                // dispatch(changegetEndYears(null));
                // dispatch(changegetStartMonth(null));
                // dispatch(changegetEndMonth(null));
                // dispatch(changefrequency(null));
              }}
              styles={customStyles}
              placeholder="Select State"
              isDisabled={stateDisabled}
            />
          </div>
          <div className="download-container">
            <Select
              className="custom-select"
              value={{
                value: districtName,
                label: districtName ? districtName : getLeveldistrictPlaceholder(props.countryName),
              }}
              onChange={(obj) => {
                dispatch(changeDistrictName(obj["value"]));
                dispatch(changeLevel2Name(obj["value"]));
                // dispatch(changeWeathervariable(null));
                // dispatch(changegetStartYears(null));
                // dispatch(changegetEndYears(null));
                // dispatch(changegetStartMonth(null));
                // dispatch(changegetEndMonth(null));
                // dispatch(changefrequency(null));
              }}
              options={districtObject}
              styles={customStyles}
              placeholder="Select District"
              isDisabled={districtDisabled}
            />
          </div>
          <div className="dropdownlabel">Weather Variable </div>
          <div className="download-container">
            <Select
              className="custom-select"
              value={{
                value: getWeatherVariable,
                label: getWeatherVariable ? getWeatherVariable : "Select",

              }}
              onChange={(obj) => {
                dispatch(changeWeathervariable(obj["value"]));
                dispatch(changeCombination(null));
                dispatch(changeRainfallDataset(null));
                dispatch(changeTempDataset(null));
                dispatch(changeVegetationDataset(null));
                // dispatch(changegetStartYears(null));
                // dispatch(changegetEndYears(null));
                // dispatch(changegetStartMonth(null));
                // dispatch(changegetEndMonth(null));
                dispatch(changefrequency(null));
              }}
              options={getWeatherObject}
              styles={customStyles}
              isDisabled={weatherDisabled}
            />
          </div>
          <div className="dropdownlabel">Time Period</div>
          <div className="dropdown-row">
            <div className="download-container" style={{ width: '45%' }}>
              <Select
                className="custom-select"
                value={{
                  value: getStartYears,
                  label: getStartYears ? getStartYears : "Start Year",
                }}
                onChange={(obj) => {
                  dispatch(changegetStartYears(obj["value"]));
                  dispatch(changegetEndYears(null));
                  // dispatch(changegetStartMonth(null));
                  // dispatch(changegetEndMonth(null));
                  // dispatch(changefrequency(null));
                }}
                options={getStartyearsObject}
                placeholder="Level 3"
                styles={customStyles}
                isDisabled={startYearDisabled}
              />
            </div>
            <div className="download-container" style={{ width: '45%' }}>
              <Select
                className="custom-select"
                value={{
                  value: getEndYears,
                  label: getEndYears ? getEndYears : "End Year",
                }}
                onChange={(obj) => {
                  dispatch(changegetEndYears(obj["value"]));
                  // dispatch(changegetStartMonth(null));
                  // dispatch(changegetEndMonth(null));
                  // dispatch(changefrequency(null));
                }}
                options={getEndyearsObject}
                placeholder="Level 3"
                styles={customStyles}
                isDisabled={EndYearDisabled}
              />
            </div>
          </div>
          <div className="dropdown-row">
            <div className="download-container" style={{ width: '45%' }}>
              <Select
                className="custom-select"
                value={{
                  value: getStartMonth,
                  label: getStartMonth ? getStartMonth : "Start Month",
                }}
                onChange={(obj) => {
                  dispatch(changegetStartMonth(obj["value"]));
                  dispatch(changegetEndMonth(null));
                  // dispatch(changefrequency(null));
                }}
                options={getStartMonthsObject}
                placeholder="Level 3"
                styles={customStyles}
                isDisabled={startMonthDisabled}
              />
            </div>
            <div className="download-container" style={{ width: '45%' }}>
              <Select
                className="custom-select"
                value={{
                  value: getEndMonth,
                  label: getEndMonth ? getEndMonth : "End Month",
                }}
                onChange={(obj) => {
                  dispatch(changegetEndMonth(obj["value"]));
                  // dispatch(changefrequency(null));
                }}
                options={getEndMonthsObject}
                placeholder="Level 3"
                styles={customStyles}
                isDisabled={EndMonthDisabled}
              />
            </div>
          </div>

          <div className="dropdownlabel">Frequency</div>
          <div className="download-container">
            <Select
              className="custom-select"
              value={{
                value: frequency,
                label: frequency ? frequency : "Select",

              }}
              onChange={(obj) => {
                dispatch(changefrequency(obj["value"]));
              }}
              options={frequencyObject}
              placeholder="Level 3"
              styles={customStyles}
              isDisabled={frequencyDisabled}
            />
          </div>

        </div>
        <div className="compare-button-container">
          <button
            className="comparedataset-button"
            onClick={handleComparebuttonlick}
            disabled={frequencyDisabled}
          >
            Compare
          </button>
          <div
            className="resetbutton"
            onClick={handleResetbuttonlick}
          >
            <LuRefreshCcw style={{ marginRight: '10px' }} />

            Reset selections
          </div>
        </div>
      </div>


    </>
  );
};

export default Compare;
