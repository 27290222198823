import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {  useSelector } from "react-redux";
import AreaRangeLineGraphTemp from "./AreaRangeLineGraphTemp";
import AreaRangeLineGraphRain from "./AreaRangeLineGraphRain";
import CircularProgress from '@mui/material/CircularProgress';
import AreaRangeLineGraphTempMonthly from "./AreaRangeLineGraphTempMonthly";
import AreaRangeLineGraphRainMonthly from "./AreaRangeLineGraphRainMonthly";
import AreaRangeLineGraphVegetation from "./AreaRangeLineGraphVegetation";
const AccordionComponentAvg = (props) => {
  const frequency = useSelector((state) => state.frequency);
  const stateName = useSelector((state) => state.stateName);
  const districtName = useSelector((state) => state.districtName);
  const getStartYears = useSelector((state) => state.getStartYears);
  const getStartMonth = useSelector((state) => state.getStartMonth);
  const getEndMonth = useSelector((state) => state.getEndMonth);
  const getEndYears = useSelector((state) => state.getEndYears);
  const RainfallDataset = useSelector((state) => state.RainfallDataset);
  const getWeatherVariable = useSelector((state) => state.getWeatherVariable);
  const [ChirpsDailyData, setChirpsDailyData] = useState(null);
  const [TamsatDailyData, setTamsatDailyData] = useState(null);
  const [Arc2DailyData, setArc2DailyData] = useState(null);
  const [Era5DailyavgRainfallData, setEra5DailyavgRainfallData] = useState(null);
  const [Era5DailyavgTemperatureData, setEra5DailyavgTemperatureData] = useState([]);
  const [Era5MonthlyavgRainfallData, setEra5MonthlyavgRainfallData] = useState(null);
  const [Era5MonthlyavgTemperatureData, setEra5MonthlyavgTemperatureData] = useState([]);
  const [ChirpsMonthlyData, setChirpsMonthlyData] = useState(null);
  const [TamsatMonthlyData, setTamsatMonthlyData] = useState(null);
  const [Arc2MonthlyData, setArc2MonthlyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ModisDailyData, setModisDailyData] = useState(null);
  const [Sentinal2DailyData, setSentinal2DailyData] = useState(null);
  const [gsMapDailyData, setgsMapDailyData] = useState(null);
  const [gsMapMonthlyData, setgsMapMonthlyData] = useState(null);
  const compareState = useSelector((state) => state.compareState);
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let datasets = [];
      let updateStates = [];
      let updateStatesveg = [];
      let vegetationdatasets = [];
      let promises = []; 
      let vegetationpromises = []; 
      if (frequency === 'Daily') {
        if(getWeatherVariable =='Rainfall' )
        {
          datasets = ['chirpsDaily', 'arc2Daily', 'tamsatDaily', 'era5DailyRainfall','gsMapDaily'];
          updateStates = [setChirpsDailyData, setArc2DailyData, setTamsatDailyData, setEra5DailyavgRainfallData,setgsMapDailyData];
        }
        else if (getWeatherVariable =='Temperature')
        {
          datasets = [ 'era5DailyTemperature'];
          updateStates = [setEra5DailyavgTemperatureData];
        }
      
      } else if (frequency === 'Monthly')  {
        if(getWeatherVariable =='Rainfall' )
        {
          datasets = ['chirpsMonthly', 'tamsatMonthly', 'arc2Monthly', 'era5MonthlyRainfall','gsMapMonthly'];
          updateStates = [setChirpsMonthlyData, setTamsatMonthlyData, setArc2MonthlyData, setEra5MonthlyavgRainfallData,setgsMapMonthlyData];
        }
        else if (getWeatherVariable =='Temperature')
        {
          datasets = [ 'era5MonthlyTemperature'];
          updateStates = [setEra5MonthlyavgTemperatureData];
        }
      
        }
        else if (frequency === '5 day')  {
          vegetationdatasets = ['sentinel2NDVI'];
          updateStatesveg = [setSentinal2DailyData];
        }
      else
      {
        vegetationdatasets = ['modisNDVI'];
      updateStatesveg = [setModisDailyData];
      }
  
      try {
        if (getWeatherVariable === 'Rainfall' || getWeatherVariable === 'Temperature') {
          promises = datasets.map((dataset, index) =>
            fetch("api/compare/compareMinMax" + (frequency === 'Daily' ? 'Daily' : 'Monthly') + "Values?country=" + props.countryName +
              "&level1=" + stateName +
              "&level2=" + districtName +
              "&start_year=" + getStartYears +
              "&end_year=" + getEndYears +
              "&start_month=" + getStartMonth +
              "&end_month=" + getEndMonth +
              "&dataset=" + dataset, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                updateStates[index](data);
              })
              .catch(error => {
                console.error("Error fetching data:", error);
                updateStates[index](null);
              })
          );
  
          await Promise.all(promises);
        } else {
          vegetationpromises = vegetationdatasets.map((vegetationdataset, index) =>
            fetch("api/compare/compareMinMaxVegetationValues?country=" + props.countryName +
              "&level1=" + stateName +
              "&level2=" + districtName +
              "&start_year=" + getStartYears +
              "&end_year=" + getEndYears +
              "&start_month=" + getStartMonth +
              "&end_month=" + getEndMonth +
              "&dataset=" + vegetationdataset, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              })
              .then(response => {
                if (!response.ok) {
                  throw new Error('Network response was not ok');
                }
                return response.json();
              })
              .then(data => {
                updateStatesveg[index](data);
              })
              .catch(error => {
                console.error("Error fetching data:", error);
                updateStatesveg[index](null);
              })
          );
  
          await Promise.all(vegetationpromises);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
            setChirpsDailyData(null);
            setChirpsMonthlyData(null);
            setArc2DailyData(null);
            setTamsatDailyData(null);
            setEra5DailyavgRainfallData(null);
            setEra5DailyavgTemperatureData(null);
            setTamsatMonthlyData(null);
            setArc2MonthlyData(null);
            setEra5MonthlyavgRainfallData(null);
            setEra5MonthlyavgTemperatureData(null);
            setgsMapDailyData(null);
            setgsMapMonthlyData(null)

      } finally {
        setLoading(false); 
      }
    }
  
    fetchData();
  }, [compareState]);
   
  return (
    <>
    {getWeatherVariable == "Rainfall" ? (
      <div>|| (props.countryName === "Cameroon") || (props.countryName === "DRC") 
        {props.countryName === "Kenya" || (props.countryName === "Sudan" && frequency === "Monthly") || props.countryName === "Zambia" || props.countryName === "Tanzania" || (props.countryName === "Bangladesh")|| (props.countryName === "Zimbabwe")|| (props.countryName === "Rwanda") || ( props.countryName == "Uganda")|| (props.countryName === "Cameroon") || (props.countryName === "DRC") || (props.countryName === "Malawi") || (props.countryName === "Ethiopia")?
          <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="chirps-content"
              id="chirps-header"
              sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
            >
              <Typography>CHIRPS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
              </div>
              ) : (
                <>
                  {frequency === 'Daily' && ChirpsDailyData && <AreaRangeLineGraphRain data={ChirpsDailyData} graphName="chirps" />}
                  {frequency !== 'Daily' && ChirpsMonthlyData && <AreaRangeLineGraphRainMonthly data={ChirpsMonthlyData} graphName="chirps" />}
                  {!ChirpsDailyData && !ChirpsMonthlyData && <Typography>No data available</Typography>}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          : null}
        {props.countryName !== "Bangladesh" ?
          <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="chirps-content"
              id="chirps-header"
              sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
            >
              <Typography>TAMSAT</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
              </div>
              ) : (
                <>
                  {frequency === 'Daily' && TamsatDailyData && <AreaRangeLineGraphRain data={TamsatDailyData} graphName="Tamsat" />}
                  {frequency !== 'Daily' && TamsatMonthlyData && <AreaRangeLineGraphRainMonthly data={TamsatMonthlyData} graphName="Tamsat" />}
                  {!TamsatDailyData && !TamsatMonthlyData && <Typography>No data available</Typography>}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          : null}
             {props.countryName=="Zambia" && frequency == "Daily"|| props.countryName=="Tanzania"  && frequency == "Daily"|| props.countryName=="Sudan"  && frequency == "Daily" || props.countryName=="Kenya"  && frequency == "Daily"|| props.countryName=="Zimbabwe"  && frequency == "Daily"|| props.countryName=="Rwanda"  && frequency == "Daily" || props.countryName=="Uganda"  && frequency == "Daily"|| (props.countryName === "Malawi" && frequency == "Daily") || (props.countryName === "Ethiopia" && frequency == "Daily")? 
              <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="chirps-content"
          id="chirps-header"
          sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
        >
          <Typography>ARC 2</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
              </div>
              ) : (
                <>
          {frequency === 'Daily' && Arc2DailyData && <AreaRangeLineGraphRain data={Arc2DailyData} graphName="Arc2" />}
          {frequency !== 'Daily' && Arc2MonthlyData && <AreaRangeLineGraphRainMonthly data={Arc2MonthlyData} graphName="Arc2" />}
          {!Arc2DailyData && !Arc2MonthlyData && <Typography>No data available</Typography>}
          </>
              )}
        </AccordionDetails>
      </Accordion>
      :
      null}
        {props.countryName === "Zambia" || props.countryName === "Tanzania"  ||  props.countryName === "Bangladesh" || props.countryName=="Kenya"|| props.countryName == "Sudan"|| props.countryName == "Zimbabwe"|| props.countryName == "Rwanda" || props.countryName == "Uganda"|| (props.countryName === "Cameroon") || (props.countryName === "DRC")|| (props.countryName === "Malawi") || (props.countryName === "Ethiopia")  ?
          <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="chirps-content"
              id="chirps-header"
              sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
            >
              <Typography>ERA 5 Rainfall</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                  <CircularProgress  style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                </div>
              ) : (
                <>
                  {frequency === 'Daily' && Era5DailyavgRainfallData && <AreaRangeLineGraphRain data={Era5DailyavgRainfallData} graphName="Eras" />}
                  {frequency !== 'Daily' && Era5MonthlyavgRainfallData && <AreaRangeLineGraphRainMonthly data={Era5MonthlyavgRainfallData} graphName="Eras" />}
                  {!Era5DailyavgRainfallData && !Era5MonthlyavgRainfallData && <Typography>No data available</Typography>}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          : null}
            { props.countryName === "Bangladesh" || props.countryName === "Zambia" || props.countryName === "Tanzania" || props.countryName=="Kenya"|| props.countryName == "Sudan"|| props.countryName == "Zimbabwe"|| props.countryName == "Rwanda" || props.countryName == "Uganda" || (props.countryName === "Cameroon") || (props.countryName === "DRC") || (props.countryName === "Malawi") || (props.countryName === "Ethiopia") ?
          <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="chirps-content"
              id="chirps-header"
              sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
            >
              <Typography>GSMAP</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                  <CircularProgress  style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
                </div>
              ) : (
                <>
                  {frequency === 'Daily' && gsMapDailyData && <AreaRangeLineGraphRain data={gsMapDailyData} graphName="Eras" />}
                  {frequency !== 'Daily' && gsMapMonthlyData && <AreaRangeLineGraphRainMonthly data={gsMapMonthlyData} graphName="Eras" />}
                  {!gsMapDailyData && !gsMapMonthlyData && <Typography>No data available</Typography>}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          : null}
      </div>
     ) : getWeatherVariable === "Temperature" ? (
      <div>
        {props.countryName === "Zambia" || props.countryName === "Tanzania"  ||  props.countryName === "Bangladesh" || props.countryName=="Kenya"|| props.countryName == "Sudan"|| props.countryName == "Zimbabwe"|| props.countryName == "Rwanda" || props.countryName == "Uganda"|| (props.countryName === "Cameroon") || (props.countryName === "DRC") || (props.countryName === "Malawi") || (props.countryName === "Ethiopia")  ?
          <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="chirps-content"
              id="chirps-header"
              sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
            >
              <Typography>ERA 5 Temperature</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
              </div>
              ) : (
                <>
                  {frequency === 'Daily' && Era5DailyavgTemperatureData && <AreaRangeLineGraphTemp data={Era5DailyavgTemperatureData} graphName="Eras" />}
                  {frequency !== 'Daily' && Era5MonthlyavgTemperatureData && <AreaRangeLineGraphTempMonthly data={Era5MonthlyavgTemperatureData} graphName="Eras" />}
                  {!Era5DailyavgTemperatureData && !Era5MonthlyavgTemperatureData && <Typography>No data available</Typography>}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          :
          <div>No Datasets available</div>
        }
      </div>
      ) : getWeatherVariable === "Vegetation" ? (
        <div>
             {frequency == "5 day" ? 
        <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="chirps-content"
              id="chirps-header"
              sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
            >
              <Typography>SENTINEL-2 </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
              </div>
              ) : (
                <>
                  { Sentinal2DailyData && <AreaRangeLineGraphVegetation data={Sentinal2DailyData} graphName="sentinel" />}
                  {/* {frequency !== '5 Day ' && Sentinal2DailyData && <AreaRangeLineGraphVegetation data={Sentinal2DailyData} graphName="sentinel" />} */}
                  { !Sentinal2DailyData && <Typography>No data available</Typography>}
                </>
              )}
            </AccordionDetails>
          </Accordion>
          :
            <Accordion sx={{ marginBottom: '20px', border: '1px solid rgba(26, 26, 0, 0.16)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="chirps-content"
              id="chirps-header"
              sx={{ backgroundColor: 'rgba(41, 41, 5, 0.03)' }}
            >
              <Typography>MODIS EVIIRS </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px' }}>
                <CircularProgress   style={{ color: 'rgba(26, 26, 0, 0.16)' }}/>
              </div>
              ) : (
                <>
                  {ModisDailyData && <AreaRangeLineGraphVegetation data={ModisDailyData} graphName="Eras" />}
                  {/* {frequency !== '10 Day ' && ModisDailyData && <AreaRangeLineGraphVegetation data={ModisDailyData} graphName="Eras" />} */}
                  {!ModisDailyData && <Typography>No data available</Typography>}
                </>
              )}
            </AccordionDetails>
          </Accordion>
}
          </div>
) : null}
    {/* {(props.countryName === "Bangladesh" && frequency === "Daily") ?
      <div>No Datasets available</div>
      : null
    } */}
  </>
    
  );
};

export default AccordionComponentAvg;
