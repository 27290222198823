import React, { useState } from "react";
import Header from "./Header";
import { Sidebar } from "./Sidebar";
import Explore from "./Explore/Explore";
import Download from "./Download/Download";
import Analytics from "./Analytics/Analytics";
import PricingAnalytics from "./PricingAnalytics/PricingAnalytics";
import Manual from "./PricingAnalytics/Manual";
import { useSelector } from "react-redux";
import ManualStep2 from "./PricingAnalytics/ManualStep2";
import { useNavigate } from 'react-router-dom';


export const MainLayout = (props) => {
  const [explorepannel, setExplorepannel] = useState(true);
  const [menu, setMenu] = useState("Explore");
  const [selectedIcon, setSelectedIcon] = useState("Climate Data Library");
  const showManual = useSelector((state) => state.showManual);
  const step = useSelector((state) => state.step);
  const showuploadsite = useSelector((state) => state.showuploadsite);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  


  const handleMenuClick = (selectedMenu) => {
    setMenu(selectedMenu);
    if(menu === "Explore")
    navigate("/explore");
    if(menu === "Explore")
      navigate("/explore");
    if(menu === "Explore")
      navigate("/explore");
  };

  return (
    <>
      <Header
        email={props.email}
        setEmail={props.setEmail}
        selectedIcon={selectedIcon}
        setSelectedIcon={setSelectedIcon}
      />

      <>
        {selectedIcon === "Climate Data Library" && (
          <div style={{ marginTop: "45px" }}>
            <Sidebar
              setMenu={handleMenuClick}
              menu={menu}
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
            />
            {menu === "Explore" ? (
              explorepannel ? (
                <Explore/>
              ) : null
            ) : menu === "Download" ? (
              <Download />
            ) : menu === "Project" ? (
              <Analytics />
            ) : (
              ""
            )}
          </div>
        )}
        {/* {selectedIcon === "Pricing" && (
          <div style={{ marginTop: "45px" }}>
            <Sidebar
              setMenu={handleMenuClick}
              menu={menu}
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
            />
            {menu === "Download" ? (
              <Download />
            ) : menu === "Project" ? (
              showManual ? (
                step === 1 ? (
                  <Manual loading={loading} setLoading={setLoading} />
                ) : step === 2 || step === 3 ? (
                  <ManualStep2 loading={loading} setLoading={setLoading} />
                ) : (
                  <PricingAnalytics />
                )
              ) : (
                <PricingAnalytics />
              )
            ) : (
              ""
            )}
          </div>
        )} */}
      </>
    </>
  );
};
